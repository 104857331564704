import React, { useState, useEffect } from 'react';
import TipButton from '../../atoms/Guia/TipButton';
import InputLabel from '../../atoms/InputLabel';

const TipCalculator = ({ onPropinaSeleccionada, total }) => {
  const [customTip, setCustomTip] = useState('');
  const [selectedTip, setSelectedTip] = useState(null); 

  useEffect(() => {
    onPropinaSeleccionada(total * selectedTip /100 );
  }, [total, onPropinaSeleccionada]);

  const handleTipClick = (percentage) => {
    const tipAmount = (total * percentage) / 100;
    setSelectedTip(percentage);
    setCustomTip('');
    onPropinaSeleccionada(tipAmount);
  };

  const handleCustomTipChange = (event) => {
    const value = event.target.value;
    if (value >= 0 && value <= 100) {
      setSelectedTip(null);
      setCustomTip(event.target.value);
    }
  };

  const handleCustomTipSubmit = () => {
    if (customTip && customTip >= 0) {
      const tipAmount = (total * customTip) / 100;
      setSelectedTip(customTip);
      onPropinaSeleccionada(tipAmount);
    }
  };

  return (
    <div className='w-full'>
      <div className='w-full flex flex-wrap sm:flex-row items-center'>
        <TipButton percentage={5} onClick={handleTipClick} />
        <TipButton percentage={10} onClick={handleTipClick} />
        <TipButton percentage={15} onClick={handleTipClick} />
        <div className='w-16 mx-4'>
          <InputLabel
            type="number"
            value={customTip}
            onChange={handleCustomTipChange}
            placeholder="Otro"
          />
        </div>
        <button onClick={handleCustomTipSubmit} className='w-20 text-white'>
          Aplicar
        </button>
      </div>
      {selectedTip && (
        <div style={{ marginTop: '20px', color: 'white' }}>
          <h2>Propina seleccionada: {selectedTip}%</h2>
        </div>
      )}
    </div>
  );
};

export default TipCalculator;
