import React from "react";
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from "react-icons/md";

const Dropdown = ({ Value, Content, isOpen, onToggle }) => {
    return (
        <div className="w-full my-2 relative z-20">
            <button
                className={`w-full h-12 flex flex-row bg-[#81808092] justify-between items-center transition-all duration-300 ${
                    isOpen ? "h-auto rounded-t-full" : "rounded-full"
                }`}
                onClick={onToggle}
            >
                <div className="text-white pl-6">{Value}</div>
                {isOpen ? (
                    <MdOutlineKeyboardArrowUp className="w-8 h-8 text-white mr-4" />
                ):(
                    <MdOutlineKeyboardArrowDown className="w-8 h-8 text-white mr-4"/>
                )}
                
            </button>
            {isOpen && (
                <div className="bg-[#81808092] rounded-br-lg rounded-bl-lg text-white p-4 text-justify">
                    {Content}
                </div>
            )}
        </div>
    );
};

export default Dropdown;
