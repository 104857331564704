import React, { useEffect, useState, useCallback } from "react";
import DataTable from "react-data-table-component";
import { GET_HISTORIAL } from "../../../apiCaja";
import { GET_CONSUMABLES, UPDATE_CONSUMABLE, UPDATE_ORDER_HISTORY } from "../../../apiAdmin";
import { toast } from "react-toastify";
import ButtonForm from "../../atoms/ButtonForm";
import useModal from "../../Hooks/useModal";
import ModalConfirmAdmin from "../../adminMolecules/ModalConfirmAdmin";
import { useNavigate } from "react-router-dom";

const TableCorte = ({ kitchenId }) => {
    const { openModal, isOpen, closeModal } = useModal();
    const [consumables, setConsumables] = useState([]);
    const [loading, setLoading] = useState(false);
    const [dataOrders, setDataOrders] = useState([]);
    const [recipeUsage, setRecipeUsage] = useState([]);
    const navigate = useNavigate();

    const fetchData = async () => {
        try {
            setLoading(true);
            const consumableData = await GET_CONSUMABLES();
            const filteredConsumables = consumableData.filter(consumable => consumable.almacen.id_cocina === parseInt(kitchenId, 10));
            setConsumables(filteredConsumables);
            const ordersData = await GET_HISTORIAL();
            const ordersFiltered = ordersData.filter(order => order?.orden[kitchenId] && order?.status === false);
            setDataOrders(ordersFiltered);
        } catch (error) {
            console.error("Error fetching data", error);
        } finally {
            setLoading(false);
        }
    };

    const calculateRecipeUsage = useCallback(() => {
        if (dataOrders.length > 0) {
            const recipeCount = {};
            dataOrders.forEach(order => {
                const kitchenOrders = order.orden[kitchenId];
                if (kitchenOrders) {
                    kitchenOrders.forEach(product => {
                        const quantity = product.quantity || 1;
                        const receta = product.receta || [];
                        const tipoBotella = product.tipo_botella; 
                        let copasADescontar = 1;
                        if (tipoBotella === "Cierto") {
                            copasADescontar = 5; 
                        }
                        receta.forEach(insumo => {
                            const insumoId = insumo.id;
                            if (insumoId) {
                                const totalCopas = quantity * copasADescontar;
                                if (recipeCount[insumoId]) {
                                    recipeCount[insumoId] += totalCopas;
                                } else {
                                    recipeCount[insumoId] = totalCopas;
                                }
                            }
                        });
                    });
                }
            });
    
            const matchedConsumables = consumables.map(consumable => ({
                ...consumable,
                usageCount: recipeCount[consumable.id] || 0,
            }));
            setRecipeUsage(matchedConsumables);
        }
    }, [dataOrders, consumables, kitchenId]);    

    useEffect(() => {
        fetchData();
    }, [kitchenId]);

    useEffect(() => {
        calculateRecipeUsage();
    }, [dataOrders, consumables, kitchenId, calculateRecipeUsage]);

    const newUnits = ( usageCount, medidas_totales ,medidas) => {
        const resto = medidas_totales - usageCount; 
        return Math.ceil( resto / medidas );
    }

    const handleUpdateOrderAndConsumables = async () => {
        const promise = new Promise(async (resolve, reject) => {
            try {
                closeModal();
                setLoading(true);
                const consumablesToUpdate = recipeUsage.filter(consumable => consumable.usageCount > 0);
    
                for (let consumable of consumablesToUpdate) {
                    const { id, medidas_totales, usageCount, medidas } = consumable;
                    const unidadesNuevas = newUnits(usageCount, medidas_totales, medidas);   
                    await UPDATE_CONSUMABLE(id, { 
                        medidas_totales: medidas_totales - usageCount, 
                        cantidad_disponible: unidadesNuevas 
                    });
                }
    
                for (let order of dataOrders) {
                    await UPDATE_ORDER_HISTORY(order.id, { status: true });
                }
    
                calculateRecipeUsage();
                navigate("/almacenes");
                resolve("Copas actualizadas 🥳🍷");
            } catch (error) {
                reject("Error al actualizar las copas 😵❗");
            } finally {
                setLoading(false);
            }
        });
    
        toast.promise(promise, {
            pending: "Actualizando copas 🧐💬",
            success: "Copas actualizadas 🥳🍷",
            error: "Error al actualizar las copas 😵❗"
        });
    };
    
    const filteredRecipeUsage = recipeUsage.filter(consumable => consumable.usageCount > 0);

    const columns = [
        { name: "ID", selector: row => row.id },
        { name: "nombre", selector: row => row.almacen.nombre },
        { name: "Unidades en barra", selector: row => row.cantidad_disponible },
        { name: "Copas en bar", selector: row => row.medidas_totales },
        { name: "Uso", selector: row => row.usageCount },
        { name:"Medidas esperadas", selector: row => (row.medidas_totales - row.usageCount)}
    ];

    return (
        <div>
            <DataTable data={filteredRecipeUsage} columns={columns} />
            { filteredRecipeUsage.length > 0 &&
                <ButtonForm onClick={openModal} bgColor={loading ? "bg-gray-200" : "bg-orange-500"} text={loading ? "Cargando..." : "Actualizar copas"} disabled={loading ? true : false} />
            }
            {isOpen &&
                <ModalConfirmAdmin onClose={closeModal} onConfirm={handleUpdateOrderAndConsumables} />
            }
        </div>
    );
};

export default TableCorte;