import React, { useState } from "react";
import { FaRegTrashAlt } from "react-icons/fa";
import { IoIosAddCircleOutline } from "react-icons/io";
import { toast } from "react-toastify";
import { useProducts } from "../../context/ProductContext";

const Contador = ({ 
    width="w-24", 
    border="border-white",
    min=0, 
    max=100,
    product,
    isInModal = false,
    contador,
    setContador
}) => {
    const { allProducts, setAllProducts, total, setTotal } = useProducts();
    const [count, setCount] = useState(product.count);

    const handleIncrement = () => {
        if (count < max) {
            setCount(count + 1);
            if (!isInModal) {
                updateProductCount(product, count + 1);
                toast.success(`Producto añadido ${product.platillo}`);
            } else {
                setContador(contador + 1)
            }
        }
    };

    const handleDecrement = () => {
        if (count > min) {
            setCount(count - 1);
            if (!isInModal) {
                updateProductCount(product, count - 1);
                toast.error(`Producto eliminado ${product.platillo}`);
            } else {
                (setContador(contador - 1))
            }
        }
    };

    const updateProductCount = (product, newCount) => {
        if (newCount === 0) {
            removeProduct(product);
        } else {
            const updatedProducts = allProducts.map(item =>
                item.id === product.id ? { ...item, count: newCount } : item
            );
            setAllProducts(updatedProducts);
            const totalDiff = (newCount - count) * product.precio;
            setTotal(total + totalDiff);
        }
    };

    const removeProduct = (product) => {
        const filteredProducts = allProducts.filter(item => item.id !== product.id);
        setAllProducts(filteredProducts);
        setTotal(total - product.precio * count);
    };

    const divClass = ` ${width} flex flex-row border-2 ${border} rounded-full h-10 p-2 justify-between items-center`;

    return (
        <div className={divClass}>
            <FaRegTrashAlt className="h-4 text-white cursor-pointer hover:text-red-500" onClick={handleDecrement} />
            <div className="rounded-full h-6 w-6 justify-center flex items-center text-white">{count}</div>
            <IoIosAddCircleOutline className="h-6 w-6 text-white hover:text-green-400 cursor-pointer" onClick={handleIncrement} />
        </div>
    );
};

export default Contador;
