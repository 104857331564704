import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { checkSession } from "../../api";
import Sidebar from "./SideBar";
import { GET_CAJA_SESSION } from "../../apiCaja";
import { useSession } from "../../context/SessionContext";

const LayoutCajaPadel = ({ children }) => {
	const navigate = useNavigate();
  const { setUser } = useSession();

  useEffect(() => {
    const verify = async () => {
        try {
            const user = await checkSession();
            if (user.role !== "CajaPadel") {
              toast.error("Acceso denegado");
              navigate(`/login`);
            }

            const cajaSession = await GET_CAJA_SESSION();
            setUser(cajaSession);

        } catch (error) {
            navigate(`/login`);
            toast.error("Sesión no válida");
        }
    };

		verify();
	}, [navigate, setUser]);

	return (
		<div className="flex min-h-screen">
			<Sidebar />
			<div className="flex-grow flex flex-col">
				<main className="h-full pl-52 pr-6 py-8 bg-white">{children}</main>
			</div>
		</div>
	);
};

export default LayoutCajaPadel;
