import React from "react";
import LargeText from "../LargeText";

const CardLastMinute = ({item, onClick}) =>{
    return(
        <button 
            onClick={onClick}
            className="w-full sm:w-1/2 border-b-2 border-white flex flex-row text-left p-4 h-full hover:border-2 focus:border-2 focus:border-yellow-300">
            <div className="w-[66%] flex flex-col space-y-[5%]">
                <LargeText text={item.platillo} textBold size="text-3xl" />
                <LargeText text={item.descripcion} />
                <LargeText text={`$ ${item.precio}`} textBold size="text-2xl"/>
            </div>
            <img src={item.imagen} className="h-24 sm:h-48" alt="lastMinute" />
        </button>
    );
}

export default CardLastMinute;