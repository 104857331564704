import React from "react";

const RadioButtonsList = ({ options, name, selectedOption, onChange }) => {
    return (
        <div className="w-full flex flex-col text-white">
            {options.map((option) => (
                <div key={option.value} className="bg-[#242424a0] px-4 py-4 my-1 rounded-md space-x-4 flex items-center">
                    <input
                        type="radio"
                        id={`radio-${option.value}`}
                        name={name}
                        value={option.value}
                        checked={selectedOption === option.value}
                        onChange={onChange}
                        className="mr-2"
                    />
                    {option.icon ? <span className="mr-2">{option.icon}</span> : null}
                    <label htmlFor={`radio-${option.value}`}>{option.label}</label>
                </div>
            ))}
        </div>
    );
};

export default RadioButtonsList;
