import React, { useEffect, useState } from "react";
import { GET_PRODUCTS_PADEL } from "../../apiPadel";
import DataTable from "react-data-table-component";
import ButtonForm from "../atoms/ButtonForm";
import useModal from "../Hooks/useModal";
import LargeText from "../atoms/LargeText";
import CreateProductPadel from "./CreateProductPadel";
import ModalAdmin from "../adminMolecules/ModalAdmin";
import ModalConfirmAdmin from "../adminMolecules/ModalConfirmAdmin";
import { toast } from "react-toastify";
import { MdOutlineEdit, MdOutlineDeleteOutline } from "react-icons/md";
import { DELETE_PRODUCT } from "../../apiAdmin";
import EditProductPadel from "./EditProdcutPadel";

const ProductosPadel = ({ historial }) => {
    const [products, setProducts] = useState([]);
    const [product, setProduct] = useState();
    console.log(product);
    const [salesData, setSalesData] = useState({});
    const { openModal, closeModal, isOpen } = useModal();
    const { openModal: openEdit, isOpen: isOpenEdit, closeModal: closeEdit } = useModal();
    const { openModal: openDelete, isOpen: isOpenDelete, closeModal: closeDelete } = useModal();

    const getProducts = async () => {
        try {
            const data = await GET_PRODUCTS_PADEL();
            setProducts(data);
        } catch (error) {
            console.error("Error fetching kitchen products", error);
        }
    };

    useEffect(() => {
        getProducts();
        calculateSales();
    }, [historial]);

    const handleClose = () => {
        closeEdit();
        closeDelete();
    }

    const handleOpenEdit = (row) => {
        openEdit();
        setProduct(row);
    }

    const handleOpenDelete = (row) => {
        openDelete();
        setProduct(row);
    }

    const handleDelete = async () => {
        try {
            await DELETE_PRODUCT(product.id);
                toast.success("Producto eliminado correctamente");
                getProducts();
                handleClose();
        } catch (error) {
            toast.error("Error al eliminar el producto");
        }
    }

    const calculateSales = () => {
        const productQuantities = {};
        if (!historial || !Array.isArray(historial)) {
            console.warn("El prop 'historial' no está definido o no es un array.");
            return;
        }
        historial.forEach((hist, histIndex) => {
            const orden = hist.orden;
            if (!orden || typeof orden !== 'object') {
                console.warn(`Historial [${histIndex}] no tiene una propiedad 'orden' válida.`);
                return;
            }
            Object.entries(orden).forEach(([orderId, productos]) => {
                if (Array.isArray(productos)) {
                    productos.forEach((producto, prodIndex) => {
                        if (!producto || typeof producto !== 'object') {
                            console.warn(`Producto en orden ID [${orderId}], índice [${prodIndex}] en historial [${histIndex}] no es un objeto válido.`);
                            return;
                        }
                        const { id, quantity } = producto;
                        if (id == null || quantity == null) {
                            console.warn(`Producto con datos incompletos en orden ID [${orderId}], índice [${prodIndex}] en historial [${histIndex}]:`, producto);
                            return;
                        }
                        productQuantities[id] = (productQuantities[id] || 0) + quantity;
                    });
                }
            });
        });
        setSalesData(productQuantities);
    };

    const columns = [
        { name: "ID", selector: row => row.id, sortable: true },
        { name: "Nombre", selector: row => row.nombre, sortable: true },
        { name: "Precio", selector: row => `$${row.precio} MX`, sortable: true },
        { name: "Cantidad vendida", selector: row => salesData[row.id] || 0, sortable: true },
        { name: "Total vendido", sortable: true,
            selector: row => {
                const cantidad = salesData[row.id] || 0;
                const precio = row.precio || 0;
                return `$ ${cantidad * precio} MX`;
        }},
        { name: "Acciones", selector: row => 
            <div className="flex flex-row space-x-2">
                <ButtonForm text={<MdOutlineEdit size={35}/>} onClick={() => handleOpenEdit(row)} bgColor="bg-blue-300" width="w-12"/>
                <ButtonForm text={<MdOutlineDeleteOutline size={35}/>} onClick={()=> handleOpenDelete(row)}  bgColor="bg-red-500" width="w-12"/>
            </div>
        }
    ];

    return (
        <div>
            <div className="w-full flex flex-row items-center justify-center space-x-4">
                <LargeText text="Productos" textAlign="text-center" size="text-2xl" textBold colorText=""/>
                <ButtonForm text="Agregar bebida" bgColor="bg-blue-400" width="w-36" onClick={openModal} />
            </div>
            <DataTable
                data={products}
                columns={columns}
                pagination
            />
            {isOpen &&
                <ModalAdmin title="Agregar bebida" onClose={closeModal} >
                    <CreateProductPadel onClose={closeModal} refetch={getProducts} /> 
                </ModalAdmin>
            }
            {isOpenEdit &&
                <ModalAdmin title="Editar bebida" onClose={handleClose}>
                    <EditProductPadel onClose={handleClose} refetch={getProducts} row={product} /> 
                </ModalAdmin>
            }
            {isOpenDelete &&
                <ModalConfirmAdmin onClose={handleClose} onConfirm={handleDelete} />
            }
        </div>
    );
};

export default ProductosPadel;