import React, { useEffect } from "react";
import { Formik } from "formik";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigation } from "../Hooks/useNavigation";
import LargeText from "../atoms/LargeText";
import ButtonForm from "../atoms/ButtonForm";
import RegisterField from "../atoms/RegisterField";
import { login } from "../../api";
import contenedorNeon from "../assets/Imagenes/neon.png";
import contenedor from "../assets/Imagenes/OIG4.jpeg";
import H2Custom from "../atoms/H2Custom";

const Login = () => {
    const navigate = useNavigation();

    useEffect(() => {
        document.title = "Inicio de sesión - Contenedor";
    }, []); 

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const user = await login(values.emailCellphone, values.password);
            if (user.role === null) {
                navigate.navigateToHome();
            } else if (user.role === "CocinaAdmin") {
                navigate.navigateToCocina();
            } else if (user.role === "Caja") {
                navigate.navigateToCaja();
            } else if (user.role === "CajaPadel") {
                navigate.navigateToCajaPadel();
            } else if (user.role === "Admin") {
                navigate.navigateToAdmin();
            } else if (user.role === "Bar") {
                navigate.navigateToAdmin();
            }
        } catch (error) {
            console.error(error);
        } finally {
            setSubmitting(false);
        }
    };

    const handleCancel = () => {
        navigate.navigateToAbout();
    };

    const validate = (values) => {
        const errors = {};
        if (!values.emailCellphone) {
            errors.emailCellphone = "Campo requerido";
        }
        if (!values.password) {
            errors.password = "Campo requerido";
        }
        return errors;
    };

    return (
        <div
            style={{
                backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, .8), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, .8)), url(${contenedor})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
            }}
            className="w-screen h-screen bg-black flex justify-center items-center flex-col px-4"
        >
            <Formik
                initialValues={{ emailCellphone: "" }}
                validate={validate}
                onSubmit={handleSubmit}
            >
                {({ handleSubmit, isSubmitting }) => (
                    <form 
                        className="w-full sm:w-1/3 flex flex-col items-center justify-center text-center"
                        onSubmit={handleSubmit}    
                    >
                        <img src={contenedorNeon} alt="contenedor" />
                        <H2Custom text="Inicia sesión" size="text-5xl" textBold={true}/>
                        <LargeText text="Ingresa tu número de telefono o correo electronico y tu contraseña" textBold={true} />
                        <RegisterField
                            name="emailCellphone"
                            type="text"
                            placeholder="Número de teléfono o email"
                        />
                        <RegisterField
                            name="password"
                            type="password"
                            placeholder="contraseña"
                        />
                        <ButtonForm 
                            text={isSubmitting ? "Cargando..." : "Continuar"} 
                            bgColor={isSubmitting ? "bg-gray-400" : "bg-[#578E76]"} 
                            type="submit" 
                            disabled={isSubmitting} 
                        />
                        {/*
                        <LargeText text="o Ingresa con:" textBold={true} />
                        <ButtonForm text="Continuar con Google" icon={<FaGoogle size={25} />} bgColor="bg-white" textColor="text-black" disabled />
                        <ButtonForm text="Continuar con Facebook" icon={<FaFacebook className="text-blue-500" size={25} />} bgColor="bg-white" textColor="text-black" disabled />
                        */}
                        <FaArrowLeftLong className="text-yellow-300 w-24 h-6 mt-4 cursor-pointer" onClick={handleCancel} />
                    </form>
                )}
            </Formik>
        </div>
    );
}

export default Login;
