import React from "react";
import ondas from "../assets/Imagenes/ondas-sonoras.png"
import "./styles.css"
import { useMusicPlayer } from "../../context/MusicPlayerContext";

const MusicPlayer = () => {
    const { isPlaying, handleClick}  = useMusicPlayer();

    return(
        <div className="hidden md:flex">
            {isPlaying ? (
                 <div onClick={handleClick} className="w-24">
                    <div class="loader">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
            ):
                <div className="cursor-pointer h-24 w-24">
                    <img onClick={handleClick} src={ondas} width="full" alt="Ondas sonoras"/>
                </div>
            }
        </div>
    );
}

export default MusicPlayer;