import React from "react";
import { Field, ErrorMessage } from "formik";

const CustomDatePicker = ({ field, form, ...rest }) => {
  const { name, value, onChange, onBlur } = field;

  return (
    <div className="form-group w-full relative">
      <input
        type="date"
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        className="w-full focus:border-yellow-300"
        {...rest}
      />
      <ErrorMessage name={name}>
      {(message) => (
        <div className="absolute right-0 text-sm text-yellow-300">{message}</div>
      )}
    </ErrorMessage>
    </div>
  );
};

const DatePickerField = ({ ...props }) => (
  <Field component={CustomDatePicker} {...props} className="w-full rounded-full p-1.5 bg-transparent border-2 border-white text-center text-white focus:border-yellow-300" />
);

export default DatePickerField;

