import React, { useState, useEffect } from "react";
import LargeText from "../../atoms/LargeText";
import CocinaModalCarrito from ".././Carrito/CocinaModalCarrito";
import ButtonForm from "../../atoms/ButtonForm";
import { FaRegTrashCan } from "react-icons/fa6";
import { GrMapLocation } from "react-icons/gr";
import { CiCirclePlus } from "react-icons/ci";
import { MdKeyboardArrowRight } from "react-icons/md";
import { IoMdCloseCircle } from "react-icons/io";
import useModal from "../../Hooks/useModal";
import Modal from "../../molecules/Modal"
import LastMinute from "./LastMinute";
import { toast } from "react-toastify";
import { Usuario } from "../../data/DataUsuario";
import { useNavigation } from "../../Hooks/useNavigation";
import ModalConfirm from "../ModalConfirm";
import { useProducts } from "../../../context/ProductContext";
import { getKitchen } from "../../../api";

const ModalCarrito = ({ onClose }) => {
    const [kitchens, setKitchens] = useState([]);
    const { allProducts, setAllProducts, total, setTotal } = useProducts();
    const { isOpen, openModal, closeModal } = useModal();
    const { isOpen:isOpenConfirm, openModal:openConfirm, closeModal: closeConfirm } = useModal();
    const navigate = useNavigation();

    useEffect (()=>{
        const fetchKitchens = async () =>{
                try{
                        const data = await getKitchen();
                        setKitchens(data);
                } catch (error) {
                        console.log("Error al obtener los datos", error);
                }
        };
        fetchKitchens()
    }, []);

    const HandleVaciar = () =>{
        setAllProducts([]);
        setTotal(0);
        toast.error("Carritos vaciados correctamente");
        onClose();
    }

    const handleClose = () => {
        onClose();
        navigate.navigateToCocinasSection();
    }

    const cocinasConProductos = kitchens.filter(cocina =>
        allProducts.some(product => product.cocina === cocina.id)
    );
    const direccionPrincipal = Usuario[0].direcciones.find(direccion => direccion.main) || {};

    return (
        <div className="top-14 w-full max-h-[92vh] sm:h-auto sm:max-h-fit overflow-auto flex flex-row absolute z-30">
            <div className="w-0 sm:w-2/3" onClick={onClose}></div>
            <div className="h-full w-full sm:w-1/3 bg-lime-950 px-4 py-4 space-y-4">
                {allProducts.length ? (
                <>
                <div className="h-[10%] flex flex-col border-b-8 border-yellow-300">
                    <div className="flex flex-row justify-between items-center">
                        <LargeText text="Tu carrito" textBold={true} size="text-4xl" />
                        <IoMdCloseCircle size={30} className="text-white cursor-pointer " onClick={onClose}/>
                    </div>
                    <div>
                        <ButtonForm 
                            icon={<GrMapLocation size={30} />} 
                            text={
                                `${direccionPrincipal.calle}, 
                                ${direccionPrincipal.colonia}, 
                                ${direccionPrincipal.cp}, 
                                ${direccionPrincipal.municipio}`
                            } 
                            icon2={<MdKeyboardArrowRight size={30} className="ml-2" />} 
                            divStyles="text-justify"
                            textBold
                            onClick={navigate.navigateToDirectionsSection}
                        />
                    </div>
                </div>
                {cocinasConProductos.map((cocina, index) => (
                    <CocinaModalCarrito
                        key={index}
                        cocina={cocina}
                        closeCarrito={onClose}
                    />
                ))}
                <ButtonForm text={`Ir a pagar  Total: ${total}`} bgColor="bg-yellow-300" textColor="text-black" onClick={openModal}/>
                {isOpen &&
                    <Modal title="¿Un ultimo antojo?" width="w-full" height="h-full" onClose={closeModal}>
                        <LastMinute onClose={closeModal} />
                    </Modal>
                }
                <ButtonForm icon={<FaRegTrashCan />} text="Vaciar todos los carritos" onClick={openConfirm} bgColor="bg-red-500" textColor="text-white"/>
                {isOpenConfirm &&
                    <ModalConfirm onClose={closeConfirm} onConfirm={HandleVaciar} />
                }
                </>
                ) : ( 
                    <div className="h-screen w-full flex flex-col items-center justify-center">
                        <LargeText  text="Aún no tienes productos en tu carrito" textBold />
                        <CiCirclePlus  className="w-64 h-64 text-white cursor-pointer" onClick={handleClose}/>
                        <LargeText  text="Ir a ver las cocinas" textBold />
                    </div>
                )}
            </div>
        </div>
    );
};

export default ModalCarrito;