import React, { useState, useEffect } from "react";
import LargeText from "../../atoms/LargeText";
import MenuCard from "../../atoms/MenuCard";
import { BsArrowRight, BsArrowLeft, BsArrowUp } from "react-icons/bs";
import { Menu } from "../../data/DataMenu";
import useModal from "../../Hooks/useModal";
import ModalProduct from "../Carrito/ModalProduct";
import { useProducts } from "../../../context/ProductContext";

const Platillos = ({ cocina }) => {
    const { allProducts } = useProducts();

    const { openModal, isOpen, closeModal } = useModal();
    const [currentPage, setCurrentPage] = useState(0);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const itemsPerPage = 6;
    const filteredMenu = Menu.filter((item) => item.cocina === cocina?.id);

    useEffect(() => {
        setCurrentPage(0);
    }, [cocina]);

    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentItems = filteredMenu.slice(startIndex, endIndex);
    const totalPages = Math.ceil(filteredMenu.length / itemsPerPage);

    const handlePrevPage = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages - 1) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handleAddProduct = (product) => {
        const foundProduct = allProducts.find(p => p.id === product.id);
        setSelectedProduct(foundProduct || product);
        openModal();
    };

    return (
        <div className="w-full h-fit py-4 sm:py-0 sm:h-full">
            {!cocina ? (
                <div className="text-white flex flex-col justify-center text-center items-center h-full w-full ">
                    <BsArrowUp className="text-yellow-300 flex sm:hidden" size={50} />
                    <LargeText text="Selecciona una cocina" colorText="text-yellow-300" textBold size="text-4xl sm:text-6xl" />
                    <BsArrowRight className="text-yellow-300 hidden sm:flex" size={100} />
                </div>
            ) : (
                <section className="h-full flex flex-col justify-around sm:justify-between">
                    <header className="w-full hidden sm:flex flex-row"> 
                        <div className="flex-col justify-between items-center pl-4">
                            <LargeText
                                text="Cocina seleccionada"
                                size="text-4xl"
                                textBold={true}
                            />
                            <LargeText
                                text={cocina.nombre}
                                size="text-3xl"
                                textBold={true}
                                colorText="text-yellow-300"
                            />
                            <LargeText
                                text="Selecciona los productos que deseas agregar"
                                size="text-3xl"
                                textBold={true}
                            />
                        </div>
                        <div className="hidden sm:flex pl-12">
                            {filteredMenu.length > itemsPerPage && (
                                <div className="w-auto flex flex-row px-4 space-x-4">
                                    <BsArrowLeft
                                        className={`${cocina.text} w-16 h-12 cursor-pointer`}
                                        onClick={handlePrevPage}
                                    />
                                    <BsArrowRight
                                        className={`${cocina.text} w-16 h-12 cursor-pointer`}
                                        onClick={handleNextPage}
                                    />
                                </div>
                            )}
                        </div>
                    </header>
                    {filteredMenu.length === 0 ? (
                        <div className="text-center h-full w-full flex items-center justify-center">
                            <LargeText text="No hay productos disponibles en esta cocina" size="text-4xl" textBold={true} />
                        </div>
                    ) : (
                        <div className="flex flex-row sm:flex-wrap w-full h-fit sm:h-[80%] space-x-4 sm:space-x-0 overflow-x-auto px-4 sm:px-0">
                            {currentItems.map((platillo) => (
                                <MenuCard
                                    key={platillo.id}
                                    platillo={platillo}
                                    onClick={() => handleAddProduct(platillo)}
                                    colorText={cocina.text}
                                />
                            ))}
                        </div>
                    )}
                </section>
            )}
            {isOpen && selectedProduct && (
                <ModalProduct
                    cocina={cocina}
                    item={selectedProduct}
                    onClose={closeModal}
                />
            )}
        </div>
    );
};

export default Platillos;
