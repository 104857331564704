import React, { useState, useEffect } from "react";
import useModal from "../../Hooks/useModal";
import { FaCcMastercard } from "react-icons/fa";
import Link from "../../atoms/Link";
import LargeText from "../../atoms/LargeText";
import ButtonForm from "../../atoms/ButtonForm";
import InputLabel from "../../atoms/InputLabel";
import Modal from "../../molecules/Modal";
import Costos from "../../molecules/Guia/Costos";
import CardCocina from "../../molecules/Guia/CardCocina";
import CambiarPago from "../../molecules/Guia/CambiarPago";
import TipCalculator from "../../molecules/Guia/TipCalculator";
import AgregarTarjeta from "../../molecules/Guia/AgregarTarjeta";
import CambiarDireccion from "../../molecules/Guia/CambiarDireccion";
import PreparandoPedido from "../../molecules/Guia/PreparandoPedido";
import AgregarDireccion from "../../molecules/Guia/AgregarDireccion";
import { Cocina } from "../../data/DataCocinas";
//import TipoEntrega from "../../molecules/Guia/TipoEntrega";

const HacerPedidos = ({allProducts, setAllProducts, total, setTotal, setPagar, handleTotal, handlePropina, handleEnvio, Usuario}) =>{
    const costoEnvio = 50;
    const [propina, setPropina] = useState(0);
    const [totalPedido, setTotalPedido] = useState(total);
    const { isOpen, openModal, closeModal } = useModal();
    const { isOpen:IsOpenCambio, openModal:OpenCambio, closeModal:closeCambio } = useModal();
    const { isOpen:IsOpenPago, openModal:OpenPago, closeModal:closePago } = useModal();
    const { isOpen:IsOpenPreparando, openModal:OpenPreparando, closeModal:closePreparando } = useModal();
    const { isOpen:IsOpenAgregar, openModal:OpenAgregar, closeModal:closeAgregar} = useModal();
    const { isOpen:IsOpenAgregarDireccion, openModal:OpenAgregarDireccion, closeModal:closeAgregarDireccion} = useModal();

    const  handlePagar = () =>{
        OpenPreparando();
        handleTotal(totalPedido);
        handleEnvio(costoEnvio);
        handlePropina(propina);
        setPagar(true);
    };

    /**const handleEntregaSeleccionada = (monto) => {
        setCostoEnvio(monto);
    }; **/

    const handlePropinaSeleccionada = (monto) => {
        setPropina(monto);
    };

    const cocinasConProductos = Cocina.filter(cocina =>
        allProducts.some(product => product.cocina === cocina.id)
    ); 

    useEffect(() => {
        const costoProductos = total;
        const totalCalculado = costoProductos + costoEnvio + propina;
        setTotalPedido(totalCalculado);
    }, [total, costoEnvio, propina]);

    const direccionPrincipal = Usuario.direcciones.find(direccion => direccion.main) || {};
    const pagoPrincipal = Usuario.pagos.find(pago => pago.main) || {};

    return( 
        <main className="w-full flex flex-col sm:flex-row">
            <section className="w-full sm:w-2/3 flex flex-col space-y-8 p-4">
                <div className="w-full bg-gray-600 rounded-md p-4 space-y-4">
                    <div className="flex flex-row justify-between items-center">
                        <LargeText text="Direccion de entrega" textBold />
                        <Link value="Cambiar" text="text-yellow-300" onClick={OpenCambio}  />
                    </div>
                    <div className="h-[.5px] w-full border-[1px] border-white " />
                    <LargeText 
                        text={
                            `${direccionPrincipal.calle}, 
                            ${direccionPrincipal.colonia}, 
                            ${direccionPrincipal.cp}, 
                            ${direccionPrincipal.municipio}, 
                            ${direccionPrincipal.estado}`
                        } 
                        textBold 
                    />
                    <InputLabel etiqueta="Instrucciones de entrega (Opcional)" placeholder="Detalles adicionales..."/>
                    <LargeText text="Productos por cocina" textBold />
                    <div className="h-[.5px] w-full border-[1px] border-white " />
                    {cocinasConProductos.map((cocina, index)=>{
                            const productosPorCocina = allProducts.filter(product => product.cocina === cocina.id);
                            return(
                            <CardCocina key={index} cocina={cocina} cantidad={productosPorCocina.length} allProducts={allProducts} />
                        );})}
                    {/**<TipoEntrega onEntregaSeleccionada={handleEntregaSeleccionada} />**/}
                </div>
                <div className="w-full bg-gray-600 rounded-md p-4 space-y-4">
                    <div className="flex flex-row justify-between items-center">
                        <LargeText text="Metodo de pago" textBold />
                        <Link value="Cambiar" text="text-yellow-300" onClick={OpenPago}/>
                    </div>
                    <div className="h-[.5px] w-full border-[1px] border-white " />
                    <div className="flex flex-row items-center">
                        <FaCcMastercard className="w-16 h-16 text-white mr-4" />
                        <div className="flex flex-col">
                            <LargeText text={`Tarjeta terminada en ${String(pagoPrincipal.numero).slice(-4)}`} textBold/>
                            <LargeText text={`${pagoPrincipal.nombre} ${pagoPrincipal.apellidos}`}/>
                        </div>
                    </div>
                </div>
                <div className="w-full bg-gray-600 rounded-md p-4 space-y-4">
                    <div className="flex flex-row justify-between items-center">
                        <LargeText text="Añade una propina" size="text-sm" textBold /> 
                        <LargeText text="El 100% de esta recompensa va al repartidor"  size="text-sm"/>
                    </div>
                    <div className="h-[.5px] w-full border-[1px] border-white " />
                    <LargeText text="¡Las entregas no serían posibles sin ellos!" textBold />
                    <TipCalculator total={total} onPropinaSeleccionada={handlePropinaSeleccionada}  />
                </div>
            </section>
            <section className="w-full h-fit sm:w-1/3 flex flex-col space-y-8 p-4">
                <div className="w-full bg-gray-600 rounded-md p-4 space-y-2 flex flex-col">
                    <LargeText text="Añade una propina" textBold /> 
                    <div className="h-[.5px] w-full border-[1px] border-white " />
                        <div className="flex flex-row justify-between">
                            <LargeText text="Costo de productos" /> 
                            <LargeText text={`$ ${total}`} />
                        </div>
                        <div className="flex flex-row justify-between">
                            <LargeText text="Costo de envío" /> 
                            <LargeText text={`$ ${costoEnvio}`} />
                        </div>
                        <div className="flex flex-row justify-between">
                            <LargeText text="Impuestos y Tarifas" /> 
                            <LargeText text={`%`} />
                        </div>
                        <div className="flex flex-row justify-between">
                            <LargeText text="Propina" /> 
                            <LargeText text={`$ ${propina}`} />
                        </div>
                        <div className="flex flex-row justify-between">
                            <LargeText text="Total" colorText="text-yellow-300" textBold /> 
                            <LargeText text={`$ ${totalPedido}`} textBold />
                        </div>
                    <Link value="Así funcionan nuestros costos" width="ml-auto" text="text-green-700" onClick={openModal}/>
                </div>
                <ButtonForm text="Hacer pedido" bgColor="bg-yellow-300" onClick={handlePagar} textColor="text-black"/>
                {isOpen &&
                    <Modal title="Nuestros costos" width=" w-full sm:w-[75%]" onClose={closeModal} >
                        <Costos onClose={closeModal}/>
                    </Modal> 
                }
                {IsOpenCambio &&
                    <Modal title="Cambiar de dirección" height="h-auto" Button="Agregar" onClick={OpenAgregarDireccion} onClose={closeCambio} >
                        <CambiarDireccion onClose={closeCambio} Usuario={Usuario}/>
                        {IsOpenAgregarDireccion &&
                            <Modal title="Agregar una nueva dirección" height="h-auto" onClose={closeAgregarDireccion}>
                                <AgregarDireccion onClose={closeAgregarDireccion}/>
                            </Modal>
                        }
                    </Modal> 
                }
                {IsOpenPago &&
                    <Modal title="Mis metodos de pago" height="h-auto" Button="Agregar" onClick={OpenAgregar} onClose={closePago} width="w-3/5" >
                        <CambiarPago onClose={closePago} Usuario={Usuario} />
                        {IsOpenAgregar &&
                            <Modal title="Agregar nueva tarjeta" height="h-auto" width="w-3/5" onClose={closeAgregar} >
                                <AgregarTarjeta onClose={closeAgregar} />
                            </Modal> 
                        }
                    </Modal> 
                }
                {IsOpenPreparando &&
                    <PreparandoPedido onClose={closePreparando}/>
                }
            </section>
        </main>
    )
}

export default HacerPedidos;