import React from "react";
import ButtonForm from "../../atoms/ButtonForm";
import LargeText from "../../atoms/LargeText";

const Costos = ({onClose}) =>{
    return (
        <section className="w-full h-fit space-y-6 flex flex-col items-center pb-6 sm:pb-0">
            <LargeText 
                textAling="text-justify"
                text="Queremos informarle que nuestros precios están cuidadosamente calculados y reflejan el costo real de los productos en el mercado. 
                    Nos esforzamos por ofrecerle siempre la mejor calidad a precios justos, basándonos en dos factores fundamentales:" 
                />
            <LargeText
                textAling="text-justify"
                text={
                    <ol type="1" className="space-y-4 pl-6">
                        <li>
                            1.- Coste de los agricultores locales: Valoramos y apoyamos a nuestros agricultores locales, quienes trabajan arduamente para proporcionar productos frescos y de alta calidad.
                            Los precios de estos productos se determinan en función del costo directo de producción que ellos enfrentan, asegurando así una justa compensación por su dedicación y esfuerzo.
                        </li>
                        <li>
                            2.- Productos importados: Para aquellos productos que no se encuentran disponibles localmente, recurrimos a importaciones. 
                            Los precios de estos artículos importados incluyen los costos asociados con la compra en el mercado internacional, 
                            el transporte y las tasas de aduana. Nos comprometemos a mantener un equilibrio, ofreciendo productos de alta calidad a precios competitivos.
                        </li>
                    </ol>
                }
            />
                <LargeText 
                    textAling="text-justify"
                    text="Entendemos la importancia de la transparencia en la fijación de precios y nos enorgullece poder garantizar que nuestros precios 
                    reflejan fielmente el valor y la calidad de los productos que ofrecemos. 
                    Agradecemos su confianza y apoyo continuo."
                />
            <ButtonForm text="Aceptar" bgColor="bg-yellow-400" textColor="text-black" width="w-1/2" onClick={onClose} />
        </section>
    );
}

export default Costos;