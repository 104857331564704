import React from "react";
import LargeText from "./LargeText";

const InputField = ({etiqueta="", value, placeholder={},icon, onChange, required=false, ...rest}) =>{
    return( 
        <div className="w-auto flex flex-col my-2 pr-4 ">
            <LargeText text={etiqueta} textBold />
            <div className="relative w-full flex">
              {icon && (
              <span className="flex items-center justify-center mx-4">{icon}</span>
            )}
        <input
          placeholder={placeholder}
          className={`bg-transparent border-b-2 w-full border-white text-white pb-2 pl-${icon ? '10' : '3'} placeholder-gray`}
          value={value}
          required={required}
          onChange={onChange}
          {...rest}
        />
      </div>
    </div>
    );
}

export default InputField;