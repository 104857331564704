import React from "react";
import LargeText from "./LargeText";
import { toast } from "react-toastify";

const MenuCard = ({platillo, onClick, colorText}) =>{

    const precioFormat = `$ ${platillo.precio}` ;
    return(
        <button 
            className={`h-44 sm:h-[50%] min-w-44 max-w-44 sm:min-w-[33.33%] rounded-full sm:rounded-none flex flex-col 
                        ${platillo.count <= 0 ? ("bg-opacity-10 bg-white border-2 border-black hover:border-red-500 focus:border-yellow-300 cursor-default")
                            : ("border-2 border-gray-800 sm:border-transparent hover:border-green-500 focus:border-green-300") } `}
            onClick={platillo.count <= 0 ? ()=>toast.warn("Producto no disponible") : onClick }
        >
            <div className="h-1/2 flex justify-center items-center">
                <img src={platillo.imagen} className={`w-[50%] ${platillo.count <= 0 ? ("opacity-50"):("")} `} alt="Logo" />
            </div>
            <div className="w-full h-1/2 px-4">
                <LargeText text={platillo.platillo} size={`text-base sm:text-lg ${platillo.count <= 0 ?("opacity-50") : ("")} `} textBold={true}/>
                <LargeText text={platillo.descripcion} textBold={true} size={`hidden sm:flex sm:justify-center text-sm ${platillo.count <= 0 ?("opacity-50") : ("")} `}  />
                <LargeText text={precioFormat} colorText={` ${colorText} ${platillo.count <= 0 ?("opacity-50") : ("")} `} textBold={true}  />
            </div>
        </button>
    );
}

export default MenuCard;