import React from "react";
import ButtonForm from "../../atoms/ButtonForm";
import tarjeta from "../../assets/Imagenes/tarjeta.png"
import { toast } from "react-toastify";
import { Formik } from "formik";
import InputFieldFormik from "../../atoms/InputFieldFormik";

const AgregarTarjeta = ({onClose}) =>{
    const validate = (values) => {
        const errors = {};
        if (!values.card) {
            errors.card = "Campo requerido";
        } else if (!/^\d{16}$/.test(values.card)) {
            errors.card = "Debe ser una tarjeta valida de 16 dígitos";
        }
        if (!values.cvv) {
            errors.cvv = "Campo requerido";
        } else if (!/^\d{3}$/.test(values.cvv)) {
            errors.cvv = "Debe ser un cvv valido de 3 dígitos";
        }
        if (!values.name) {
          errors.name = "Campo requerido";
        } else if (!/^[a-zA-Z\s]+$/.test(values.name)) {
          errors.name = "Solo se permiten letras y espacios";
        }
        if (!values.lastname) {
          errors.lastname = "Campo requerido";
        } else if (!/^[a-zA-Z\s]+$/.test(values.lastname)) {
          errors.lastname = "Solo se permiten letras y espacios";
        }
        if (!values.fecha) {
          errors.fecha = "Campo requerido";
        }
        return errors;
      };

    const handleSubmit = (values) =>{
        alert(JSON.stringify(values, null, 2));
        toast.success("Tarjeta agregada correctamente")
        onClose();
    }
    return(
        <Formik
        initialValues={{ name: "", lastname: "", card: "", fecha:"", cvv:"" }}
        validate={validate}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit }) => (
        <form className="flex flex-col w-full items-center justify-center" onSubmit={handleSubmit}>
            <img src={tarjeta} className="w-60" alt="Tarjeta" />
            <InputFieldFormik
                name="card"
                label="Número de la tarjeta"
                type="number"
                placeholder="Introduzca el número de la tarjeta"
            />
            <div className="flex flex-col sm:flex-row w-full sm:space-x-4 space-y-2 sm:space-y-0 py-2">
                <InputFieldFormik
                    name="name"
                    label="Nombre(s)"
                    type="text"
                    placeholder="Nombre(s)"
                />
                <InputFieldFormik
                    name="lastname"
                    label="Apellido(s)"
                    type="text"
                    placeholder="Apellido(s)"
                />
            </div>
            <div className="flex w-full flex-col sm:flex-row sm:space-x-4 space-y-2 sm:space-y-0 py-2">
                <InputFieldFormik
                    name="fecha"
                    label="Fecha de expiración"
                    type="date"
                />
                <InputFieldFormik
                    name="cvv"
                    label="Código CVV"
                    type="number"
                    placeholder="Código CVV"
                />
            </div>
            <ButtonForm text="Siguiente" bgColor="bg-yellow-300" type="submit" textColor="text-black mt-4" />
        </form>
    )}
    </Formik>
    );
}

export default AgregarTarjeta;