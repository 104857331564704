import React from "react";
import LargeText from "./LargeText";
import { PiSealCheckFill, PiConfettiThin, PiChefHatThin } from "react-icons/pi";

const ModalComprobante = ({onClose}) => {
    return (
        <div className="w-full h-full flex flex-col justify-center items-center sm:p-16 relative" onClick={onClose}>
            <PiConfettiThin className="absolute top-0 left-0 opacity-30 text-white" size={50} />
            <PiConfettiThin className="absolute top-1/4 left-1/4 opacity-30 text-white" size={50} />
            <PiConfettiThin className="absolute top-1/2 left-1/2 opacity-30 text-white" size={50} />
            <PiConfettiThin className="absolute top-3/4 left-3/4 opacity-30 text-white" size={50} />
            <PiConfettiThin className="absolute bottom-0 right-0 opacity-30 text-white" size={50} />
            <PiConfettiThin className="absolute top-0 right-1/2 opacity-30 text-white" size={50} />
            <PiConfettiThin className="absolute bottom-1/2 right-0 opacity-30 text-white" size={50} />
            
            <PiChefHatThin className="absolute bottom-0 left-1/2 opacity-30 text-white" size={50} />
            <PiChefHatThin className="absolute bottom-1/2 left-0 opacity-30 text-white" size={50} />
            <PiChefHatThin className="absolute bottom-0 left-0 opacity-30 text-white" size={50} />
            <PiChefHatThin className="absolute top-3/4 left-1/4 opacity-30 text-white" size={50} />
            <PiChefHatThin className="absolute bottom-1/2 left-1/2 opacity-30 text-white" size={50} />
            <PiChefHatThin className="absolute top-1/4 right-1/4 opacity-30 text-white" size={50} />
            <PiChefHatThin className="absolute top-0 right-0 opacity-30 text-white" size={50} />

            <PiSealCheckFill color="white" size={150} />
            <LargeText 
                text="Su comprobante se envió al número proporcionado de WhatsApp" 
                size="text2-xl sm:text-4xl"
                textAling="text-center"
                textBold
            />
        </div>
    );
}

export default ModalComprobante;
