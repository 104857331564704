import React from 'react';

const TipButton = ({ percentage, onClick }) => {
  return (
    <button onClick={() => onClick(percentage)} className='h-10 w-12 mx-4 border-2 border-white text-white rounded-lg focus:border-yellow-300'>
      {percentage}%
    </button>
  );
};

export default TipButton;
