import React, { useState, useEffect } from "react";
import { getKitchen } from "../../api";
import Circle from "../atoms/Circle";

const KitchensActive = () => {
    const [circles, setCircles] = useState();

    useEffect(() => {
        const fetchKitchens = async () => {
          try {
            const kitchens = await getKitchen();
            const kitchensFiltered = kitchens.filter(kitchen => kitchen.tipo !== "CocinaPadel");
            setCircles(kitchensFiltered);
          } catch (error) {
            console.error('Error al obtener las cocinas de la base de datos', error);
          }
        };
        fetchKitchens();
      }, []);

    return(
        <div className="grid grid-cols-5">
            {circles?.map((circle) => (
                <Circle
                    key={circle.id}
                    cocina={circle}
                    Width="w-28"
                    Height="h-28"
                    selected={circle.status === "Abierto" ? true : false}
                />
            ))}
        </div>
    )
}

export default KitchensActive;