import React, { useContext, createContext, useState, useEffect } from "react";
import Musica from "../components/assets/audios/musica.mp3"

const MusicPlayerContext = createContext();

export const useMusicPlayer = () => useContext(MusicPlayerContext);

export const MusicPlayeProvider = ({ children }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [audio] = useState(new Audio(Musica));

    const handleClick = () => {
        if(audio.paused) {
            audio.play();
            setIsPlaying(true);
        } else {
            audio.pause();
            audio.currentTime = 0;
            setIsPlaying(false);
        }
    };

    useEffect(() => {
        return() => {
            audio.pause();
            audio.currentTime = 0;
        };
    }, [audio]);

    return (
        <MusicPlayerContext.Provider value={{isPlaying, handleClick}}>
            {children}
        </MusicPlayerContext.Provider>
    )
}
