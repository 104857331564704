import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import { MdOutlineArrowBackIos } from "react-icons/md";
import { useProducts } from "../../context/ProductContext";
import ButtonForm from "../atoms/ButtonForm";
import Header from "../organisms/Header";
import Footer from "../organisms/Footer";
import NoPedidos from "../organisms/Guia/NoPedidos";
import HacerPedidos from "../organisms/Guia/HacerPedidos";
import StatusPedido from "../organisms/Guia/StatusPedido";
import { Usuario } from "../data/DataUsuario";
import { getUserById } from "../../api";

const Guia = () => {
    const { allProducts, setAllProducts, total, setTotal } = useProducts();
    const navigate = useNavigate();
    const location = useLocation();
    const [pagar, setPagar]= useState(false);
    const [propina, setPropina] = useState(0);
    const [envio, setEnvio] = useState(0);
    const [totalPedido, setTotalPedido] = useState(total);
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null);

    const fetchUser = async () => {
        try {
            const userData = await getUserById();
            setUser(userData);
            setLoading(false);
        } catch (error) {
            console.error("Error al obtener el usuario", error);
            toast.error("Inicie sesion");
            navigate(`/login`);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };
    
    useEffect(() => {
        fetchUser();
    }, []);

    useEffect(() => {
        document.title = "Guia - Contenedor";
    }, []); 

    useEffect(() => {
        if (location.hash ==="top"){
            const scrollToTop = () => {
                const section= document.getElementById("top");
                if (section) {
                  section.scrollIntoView({ behavior: "smooth" });
                }
              };
              scrollToTop();
        } else {
        const scrollToSec = () => {
          const section= document.getElementById("#");
          if (section) {
            section.scrollIntoView({ behavior: "smooth" });
          }
        };
        scrollToSec();
        }
    }, [location.hash]);

    const handleTotal = (monto) =>{
        setTotalPedido(monto);
    };

    const handleEnvio = (monto) =>{
        setEnvio(monto);
    };

    const handlePropina = (monto) =>{
        setPropina(monto);
    };

    return(
        <div className="pt-12 sm:pt-0">    
            <Header 
                allProducts={allProducts} 
                setAllProducts={setAllProducts} 
                total={total} 
                setTotal={setTotal} 
            /> 
            <div className="h-fit w-screen" id="?">

                <div className="w-full" id="#" >
                <ButtonForm  text="Volver" divStyles="pl-6" icon={<MdOutlineArrowBackIos className="h-6 w-6" />} onClick={() => navigate('/home')}/>
                {allProducts.length ? (
                    <>
                    {pagar === false ? (
                        <HacerPedidos 
                            setAllProducts={setAllProducts} 
                            allProducts={allProducts} 
                            total={total} 
                            setTotal={setTotal} 
                            setPagar={setPagar} 
                            handleTotal={handleTotal} 
                            handleEnvio={handleEnvio}  
                            handlePropina={handlePropina} 
                            Usuario={Usuario[0]}
                        /> 
                    ) : (
                        <StatusPedido 
                            setAllProducts={setAllProducts} 
                            allProducts={allProducts} 
                            total={total} 
                            setTotal={setTotal} 
                            totalPedido={totalPedido} 
                            propina={propina} 
                            envio={envio} 
                            Usuario={Usuario[0]}
                        />
                    )
                    }   
                    </>
                ):(
                    <NoPedidos/>
                )}
                </div>
            
            </div> 
            <Footer/> 
        </div>
    );
}

export default Guia;