import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom"; 

const VipPage = () => {
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Página VIP - Contenedor";
    }, []); 

    return (
        <div 
            className="h-screen w-screen cursor-help"
            onClick={()=>navigate("/")}
        >
            <video
                src="/assets/laundry.mp4"
                autoPlay
                loop
                muted
                style={{ 
                    width: "100%", 
                    height: "100%", 
                    objectFit: "cover" 
                }}
            >
                Tu navegador no soporta el elemento de video.
            </video>
        </div>
    );
}

export default VipPage;
