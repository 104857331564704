import React, { useEffect} from "react";
import { useLocation } from "react-router-dom";
import { useProducts } from "../../context/ProductContext";
import { useNavigation } from "../Hooks/useNavigation";
import { FaInstagram, FaTiktok } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa6";
import Footer from "../organisms/Footer";
import LargeText from "../atoms/LargeText";
import ButtonForm from "../atoms/ButtonForm";
import Cocinas from "../molecules/Cocinas/Cocinas";
import PreguntasFrecuentes from "../molecules/About/PreguntasFrecuentes";
import Header from "../organisms/Header";
import logo from "../assets/Imagenes/neon.png"
import cafe from "../assets/Imagenes/cafe.png"
import uo1 from "../assets/Imagenes/uoHome.jpg"
import citar from "../assets/Imagenes/citar.png"
import uo3 from "../assets/Imagenes/uoHome3.jpg"
import uo2 from "../assets/Imagenes/uoHome2.jpg"
import plant from "../assets/Imagenes/plantHome.png"
import plato from "../assets/Imagenes/platoHome.png"
import contenedor from "../assets/Imagenes/OIG4.jpeg"
import plato2 from "../assets/Imagenes/plato2Home.png"
import motoHome from "../assets/Imagenes/motoHome.png"
import preview from "../assets/Imagenes/previewHome.png"
import H2Custom from "../atoms/H2Custom";

const HomeUser = () => {
    const location = useLocation();
    const { allProducts } = useProducts();
    const { navigateToCocinasSection, navigateToGuia } = useNavigation();
    
    useEffect(() => {
        if (location.hash === "#section-cocinas") {
          const scrollToCocinasSection = () => {
            const sectionCocinas = document.getElementById("section-cocinas");
            if (sectionCocinas) {
              sectionCocinas.scrollIntoView({ behavior: "smooth" });
            }
          };
    
          scrollToCocinasSection();
        } else if(location.hash ==="top") {
            const scrollToHomes = () => {
                const section = document.getElementById("top");
                if (section) {
                  section.scrollIntoView({ behavior: "smooth" });
                }
              };
        
              scrollToHomes();
        } else {
            const scrollToHomes = () => {
                const section = document.getElementById("#");
                if (section) {
                  section.scrollIntoView({ behavior: "smooth" });
                }
              };
        
              scrollToHomes();
        }
      }, [location.hash]);

      useEffect(() => {
        document.title = "Bienvenidos - Contenedor";
    }, []); 
      
    return(
        <div className="w-screen bg-[#006437] flex flex-col relative" id="top">
            				<div className="animation-container">
					<div className="leaf leaf1" />
					<div className="leaf leaf2" />
					<div className="leaf leaf3" />
					<div className="leaf leaf4" />
					<div className="leaf leaf5" />
					<div className="leaf leaf6" />
					<div className="leaf leaf7" />
					<div className="leaf leaf8" />
					<div className="leaf leaf1" />
					<div className="leaf leaf2" />
					<div className="leaf leaf3" />
					<div className="leaf leaf4" />
					<div className="leaf leaf5" />
					<div className="leaf leaf6" />
					<div className="leaf leaf7" />
					<div className="leaf leaf8" />
				</div>
            <Header/>
            <section className="bg-[#006437] w-full h-[100vh] sm:h-[75vh] flex flex-col sm:flex-row" id="#">
                <div className="w-full sm:w-1/2 h-full pt-14 sm:pt-0 text-center sm:text-left px-4 sm:pl-12 space-y-3">
                    <img src={logo} className="w-1/2 hidden sm:flex" alt="IMG"/>
                    <H2Custom text="¡Bienvenidos!" size="text-3xl sm:text-6xl" textBold={true} />
                    <LargeText text="Prepárense para una experiencia gastronómica y musical única." size="text-base sm:text-3xl" textBold={true} />
                    <LargeText text="Les garantizo que disfrutarán de momentos inolvidables. ¡Que empiece la magia!" size="text-base sm:text-2xl" />
                    <div className="w-full sm:w-3/4 flex flex-col sm:flex-row items-center sm:space-x-4">
                        <div className="w-full sm:w-1/2 px-12 sm:px-0" > <ButtonForm text="Hacer pedido" width="w-full border-2 border-transparent hover:border-yellow-300" onClick={navigateToGuia} disabled={allProducts.length <= 0 ? true : false }/></div>
                        <div className="w-full sm:w-1/2 px-12 sm:p-0"><ButtonForm text="Ver cocinas" bgColor="bg-yellow-300 z-10" textColor="black" onClick={navigateToCocinasSection} /></div>
                    </div>
                    <div className="w-full sm:w-3/4 flex flex-row justify-around items-center px-12">
                    <a
							href="https://www.facebook.com/profile.php?id=61559936656892&mibextid=LQQJ4d"
							target="_blank"
							className="cursor-pointer"
							rel="noopener noreferrer"
					>
                        <FaFacebookF className="h-8 sm:h-12 w-8 sm:w-12 bg-blue-700 text-white pt-1.5 rounded-lg cursor-pointer" />
                    </a>
                    <a
							href="https://www.instagram.com/contenedor_pue?igsh=MXkyaHd1NTQ0MHFnZQ=="
							target="_blank"
							className="cursor-pointer"
							rel="noopener noreferrer"
					>
                        <FaInstagram className="h-8 sm:h-12 w-8 sm:w-12 p-1 rounded-lg bg-gradient-to-tr from-pink-500 via-pink-500 to-yellow-300 text-white cursor-pointer"/>
                    </a>
                    <a
								href="https://www.tiktok.com/@chamonos_pue?_t=8oIpzjMtURy&_r=1"
								target="_blank"
								className="cursor-pointer  z-20"
								rel="noopener noreferrer"
				    >
                        <FaTiktok className="h-8 sm:h-12 w-8 sm:w-12 text-white bg-black p-1 rounded-lg cursor-pointer"/>
                    </a>
                    </div>
                </div>
                <div className="h-full w-full sm:w-1/2 relative flex ">
                    <img src={contenedor} className="absolute top-[10%] sm:top-[15%] ml-4 sm:ml-0 w-[90%] h-full rounded-tl-[30%] rounded-br-[30%]" alt="IMG"/>
                    <img src={plato} className="absolute hidden sm:flex w-[40%] left-[55%] top-[20%] " alt="IMG"/>
                    <div className="w-[50%] h-[50%] hidden md:flex absolute top-[50%] right-[70%]">
                        <div className="vapor z-20 absolute h-[10%] w-[35%] bottom-[35%] left-[30%] flex justify-around">
                            <span style={{ '--i': 1 }} className="h-4 bg-white w-4 rounded-full"/>
                            <span style={{ '--i': 2 }} className="h-4 bg-white w-4 rounded-full"/>
                            <span style={{ '--i': 3 }} className="h-4 bg-white w-4 rounded-full"/>
                            <span style={{ '--i': 4 }} className="h-4 bg-white w-4 rounded-full"/>
                            <span style={{ '--i': 5 }} className="h-4 bg-white w-4 rounded-full"/>
                            <span style={{ '--i': 6 }} className="h-4 w-4 bg-white  rounded-full"/>
                            <span style={{ '--i': 7 }} className="h-4 bg-white w-4 rounded-full"/>
                            <span style={{ '--i': 8 }} className="h-4 bg-white w-4 rounded-full"/>
                            <span style={{ '--i': 9 }} className="h-4 bg-white w-4 rounded-full"/>
                            <span style={{ '--i': 10 }} className="h-4 bg-white w-4 rounded-full"/>
                            <span style={{ '--i': 11 }} className="h-4 bg-white w-4 rounded-full"/>
                            <span style={{ '--i': 12 }} className="h-4 bg-white w-4 rounded-full"/>
                            <span style={{ '--i': 14 }} className="h-4 bg-white w-4 rounded-full"/>
                            <span style={{ '--i': 15 }} className="h-4 bg-white w-4 rounded-full"/>
                            <span style={{ '--i': 16 }} className="h-4 bg-white w-4 rounded-full"/>
                        </div>
                        <img src={cafe} className="absolute top-0 z-10" alt="IMG"/>
                    </div>
                    <div className="absolute bg-yellow-300 rounded-tr-[30%] rounded-bl-[30%] w-[90%] sm:w-[45%] h-[40%] sm:h-[30%] top-[75%] ml-4 sm:left-[45%] p-4 sm:pl-4 flex flex-col items-center z-10 ">
                        <img src={citar} className=" w-[10%] ms:w-[25%] filter brightness-0" alt="IMG"/>
                        <LargeText text="El mejor lugar para que una comida se convierta en una experiencia inolvidable" colorText="black" size="text-sm" textBold={true} />    
                    </div>
                </div>
            </section>
            <section className="bg-gray-700 h-[75vh] flex w-full relative">
                <div className="w-full sm:w-1/2 absolute sm:relative">
                    <img src={plato2} className="w-3/5 opacity-30 sm:opacity-100"  alt="IMG"/>
                </div>
                <div className="w-full sm:w-1/2 h-full pt-[20%] sm:pt-[10%] text-center sm:text-right px-8 sm:pr-16 space-y-6" >
                    <LargeText text="Nuestras cocinas" textBold={true} size="text-3xl sm:text-6xl" />
                    <div className="flex w-full h-auto justify-center sm:justify-end flex-wrap">
                        <LargeText text="Descubre las cocinas de &nbsp;" textBold={true} size="text-base sm:text-2xl" />
                        <LargeText text={'"El Contenedor",'} textBold={true} size="text-base sm:text-2xl" colorText="text-yellow-300"/>
                        <LargeText text="donde la variedad y el sabor se fusionan." textBold={true} size="text-base sm:text-2xl" />
                    </div>
                    <LargeText text="Pide a domicilio tus platos  favoritos y disfruta en casa. Pronto, disponible en nuestra app" textBold={true} size="text-base sm:text-2xl" />
                    <LargeText text="¡Esperála!" textBold={true} size="text-2xl sm:text-4xl" />
                </div>
            </section>
            <section  id="section-cocinas" className="w-full bg-black py-8">
                <Cocinas/>
            </section>
            <section className="h-[50vh] w-full bg-gradient-to-r from-black via-gray-400 to-black relative flex">
                <img src={motoHome} className="h-[100%] absolute sm:left-[10%] " alt="IMG"/>
                <img src={preview} className="h-[100%] left-[50%] absolute hidden sm:flex" alt="IMG" />
            </section>
            <section className="bg-black h-fit sm:h-[75vh] py-6 sm:py-0 flex flex-col sm:flex-row">
                <div className="w-1/2 h-full relative hidden sm:flex">
                    <img src={uo1} className="absolute z-20 top-[60%] left-[15%] w-[40%] h-[30%] rounded-tr-[30%] rounded-bl-[30%]" alt="IMG"/>
                    <img src={uo2} className="absolute top-[15%] left-[20%] z-10  w-[70%] h-[90%] rounded-tr-[20%] rounded-bl-[20%]" alt="IMG"/>
                    <img src={uo3} className="absolute z-20 top-[30%] left-[60%] w-[35%] h-[25%] rounded-tl-[30%] rounded-br-[30%]" alt="IMG"/>
                    <img src={plant} className="absolute w-[40%] z-20 h-full rotate-180" alt="IMG"/>
                </div>
                <div className="w-full sm:w-1/2 px-4 sm:px-0">
                    <div className="flex w-full h-auto justify-center sm:justify-end flex-wrap p-8">
                            <LargeText text="Preguntas &nbsp;" textBold={true} size="text-5xl" />
                            <LargeText text="frecuentes" textBold={true} size="text-5xl" colorText="text-yellow-300"/>
                    </div>
                    <LargeText text="Encuentra respuestas rápidas a tus dudas comunes. Desde métodos de pago y tiempos de entrega hasta cómo manejar alergias alimentarias y modificar pedidos, estamos aquí para ayudarte a tener la mejor experiencia." />
                    <PreguntasFrecuentes/>
                </div>
            </section>
            <div className="relative">
            <Footer/>
            </div>
        </div>
    );
}

export default HomeUser;