import React, { useState, useEffect } from "react";
import Circle from "../../atoms/Circle";
import LargeText from "../../atoms/LargeText";
import Platillos from "./Platillos";
import { getKitchen } from "../../../api";

const Cocinas = () =>{
        const [kitchens, setKitchens] = useState([]);
        const [kitchenId, setKitchenId] = useState("");
        const selectedKitchen = kitchens.find(cocina => cocina.id === kitchenId);

        useEffect (()=>{
                const fetchKitchens = async () =>{
                        try{
                                const data = await getKitchen();
                                setKitchens(data);
                        } catch (error) {
                                console.log("Error al obtener los datos", error);
                        }
                };
                fetchKitchens()
        }, []);

    return(
        <div className="w-full sm:h-screen flex flex-col-reverse sm:flex-row">
                <div className="w-full sm:w-[70%] h-fit sm:h-screen">
                        <Platillos cocina={selectedKitchen} />
                </div>
                
                <div className="w-full sm:w-[30%] flex flex-col items-center pt-4">
                        <LargeText  text="Seleccionar el" textBold={true} size="text-2xl hidden sm:flex" />
                        <LargeText text="Menú" textBold={true} size="text-4xl hidden sm:flex" colorText="text-yellow-300"/>
                        <div className="w-full h-36 sm:h-full flex flex-row sm:flex-wrap space-x-3 sm:justify-center px-4 sm:px-0 sm:py-6 overflow-scroll scroll-behavior-smooth">
                                {kitchens.map((cocina)=>(
                                        <Circle key={cocina.id} cocina={cocina} Height="min-h-20 max-h-20 sm:max-h-36 sm:min-h-36" Width="min-w-20 max-w-20 sm:max-w-36 sm:min-w-36" onClick={() => setKitchenId(cocina.id)} selected={cocina.id === kitchenId}  />
                                ))}
                        </div>
                </div>
        </div>
    );
}

export default Cocinas;