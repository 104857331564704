import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { toast } from "react-toastify";
import useModal from "../../Hooks/useModal";
import LargeText from "../../atoms/LargeText";
import ButtonForm from "../../atoms/ButtonForm";
import InputFieldFormik from "../../atoms/InputFieldFormik";
import RadioButtonsFormik from "../../atoms/RadioButtonsFormik";
import ModalConfirm from "../../molecules/ModalConfirm";
import { updateUser } from "../../../api";

const Perfil = ({ user, refetch }) => {
  const { openModal, isOpen, closeModal } = useModal();
  const options = [
    { value: "Hombre", label: "Hombre" },
    { value: "Mujer", label: "Mujer" },
    { value: "No_especifico", label: "No seleccionar" },
  ];

  const [formValues, setFormValues] = useState({
    name: user?.nombre || "",
    apellido: user?.apellido || "", 
    email: user?.email || "",
    fecha: user?.fecha_nacimiento ? user?.fecha_nacimiento.split('T')[0] : "", 
    cellphone: user?.telefono || "", 
    gender: user?.genero || "No_especifico", 
  });

  useEffect(() => {
    setFormValues({
      name: user?.nombre || "",
      apellido: user?.apellido || "", 
      email: user?.email || "",
      fecha: user?.fecha_nacimiento ? user?.fecha_nacimiento.split('T')[0] : "",
      cellphone: user?.telefono || "", 
      gender: user?.genero || "No_especifico", 
    });
  }, [user]);


  const handleSubmit = async (values) => {
    try {
      const updatedUser = {
        nombre: values.name,
        apellido: values.apellido, 
        email: values.email,
        fecha_nacimiento: values.fecha, 
        telefono: values.cellphone, 
        genero: values.gender, 
      };
      const response = await updateUser( updatedUser );
      if (response) {
        toast.success("Datos actualizados correctamente");
        refetch();
        setFormValues(values);
      } else {
        toast.error("Error al actualizar los datos");
      }
    } catch (error) {
      toast.error("Error al actualizar los datos");
      console.error("Error al actualizar los datos del usuario:", error);
    }
  };

  const handleCloseSessions = () => {
    closeModal();
    toast.warn("Sesiones cerradas correctamente");
  };

  const validate = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = "Campo requerido";
    } else if (!/^[a-zA-Z\s]+$/.test(values.name)) {
      errors.name = "Solo se permiten letras y espacios";
    }

    if (!values.apellido) {
      errors.apellido = "Campo requerido";
    } else if (!/^[a-zA-Z\s]+$/.test(values.apellido)) {
      errors.apellido = "Solo se permiten letras y espacios";
    }

    if (!values.cellphone) {
      errors.cellphone = "Campo requerido";
    } else if (!/^\d{10}$/.test(values.cellphone)) {
      errors.cellphone = "Debe ser un número de 10 dígitos";
    }

    if (!values.email) {
      errors.email = "Campo requerido";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = "Debe ser un email válido";
    }

    if (!values.fecha) {
      errors.fecha = "Campo requerido";
    }

    return errors;
  };

  return (
    <div className="w-full h-fit bg-gray-700 rounded-md p-2 sm:p-8">
      <LargeText
        text="Información de tu cuenta"
        textBold
        colorText="text-yellow-300"
      />
      <Formik
        enableReinitialize
        initialValues={formValues}
        validate={validate}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit }) => (
          <form
            onSubmit={handleSubmit}
            className="w-full flex flex-col-reverse sm:grid sm:grid-cols-2"
          >
            <div className="sm:px-2 space-y-2">
              <InputFieldFormik
                name="name"
                label="Nombre(s)"
                type="text"
                placeholder="Nombre(s)"
              />
              <InputFieldFormik
                name="email"
                label="Correo electrónico"
                type="text"
                placeholder="correo@axample.com"
              />
              <InputFieldFormik
                name="fecha"
                label="Fecha de nacimiento"
                type="date"
              />
              <div className="flex flex-col-reverse sm:flex-row sm:space-x-4">
                <ButtonForm
                  text="Cerrar otras sesiones"
                  onClick={openModal}
                  bgColor="bg-transparent border-2 border-white"
                />
                <ButtonForm
                  text="Actualizar los datos"
                  bgColor="bg-yellow-300"
                  textColor="text-black"
                  type="submit"
                />
              </div>
            </div>
            <div className="space-y-2 sm:px-2">
              <InputFieldFormik
                name="apellido"
                label="Apellido(s)"
                type="text"
                placeholder="Apellido(s)"
              />
              <InputFieldFormik
                name="cellphone"
                label="Celular"
                type="number"
                placeholder="celular"
              />
              <RadioButtonsFormik
                name="gender"
                title="Género"
                options={options}
              />
            </div>
            {isOpen && (
              <ModalConfirm onClose={closeModal} onConfirm={handleCloseSessions} />
            )}
          </form>
        )}
      </Formik>
    </div>
  );
};

export default Perfil;
