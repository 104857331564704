import React from "react";
import "./styles.css";

const Titulo = ({ Letras }) => {
	return (
		<h2 className="titulo text-4xl sm:text-6xl md:text-8xl">
			{Letras}
		</h2>
	);
};

export default Titulo;
