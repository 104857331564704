import React, { useState } from "react";
import { MdOutlineArrowBackIos } from "react-icons/md";
import LargeText from "../../atoms/LargeText";
import ButtonForm from "../../atoms/ButtonForm";
import CardHistorialPedidos from "../../molecules/Configuracion/CardHistorialPedidos";
import sushi from "../../assets/Imagenes/vecteezy_ai-generated-sushi-clip-art_40209674.png"

const Pedidos = () => {
    const [verPedido, SetVerPedido] = useState(false);

    const pedidos = [
        { 
            imagen:sushi,
            titulo:"Sushi",
            fecha:"19/05/2024",
            total:"113",
            cantidad:"1"
        },
        { 
            imagen:sushi,
            titulo:"Hamburguesa",
            fecha:"19/05/2024",
            total:"121",
            cantidad:"1"
        },
        { 
            imagen:sushi,
            titulo:"Tacos",
            fecha:"19/05/2024",
            total:"29000",
            cantidad:"9311"
        },
        { 
            imagen:sushi,
            titulo:"Tacos",
            fecha:"19/05/2024",
            total:"29000",
            cantidad:"9311"
        },
        { 
            imagen:sushi,
            titulo:"Tacos",
            fecha:"19/05/2024",
            total:"29000",
            cantidad:"9311"
        },
    ]

    return (
        <div className="w-full h-full bg-gray-700 rounded-md p-2 sm:p-8">
            {verPedido ? (<ButtonForm icon={<MdOutlineArrowBackIos className="h-6 w-6" />} onClick={() => verPedido(false)} divStyles="pl-6"/>):(null)}
            <LargeText text={verPedido === false ? "Historial de pedidos" : "Pedido"} textBold colorText="text-yellow-300" />
            {verPedido ? (
                <div>

                </div>
            ) : (
                <div className="w-full h-[90%]">
                    <LargeText 
                        text="Haz seguimiento al detalle de tus pedidos anteriores y solicita ayuda si hay algun inconveniente con una de tus compras" 
                        size="text-sm"
                        textBold
                    />
                    <div className="w-full h-full overflow-y-scroll scroll-behavior-smooth">
                        {pedidos.map((pedido, index) => (
                            <CardHistorialPedidos 
                                key={index}
                                imagen={pedido.imagen}
                                titulo={pedido.titulo}
                                fecha={pedido.fecha}
                                total={pedido.total}
                                cantidad={pedido.cantidad}
                            />
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
}

export default Pedidos;