import React from "react";
import { toast } from "react-toastify";
import { Formik, Field } from "formik";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigation } from "../../Hooks/useNavigation";
import LargeText from "../../atoms/LargeText";
import ButtonForm from "../../atoms/ButtonForm";
import RegisterField from "../../atoms/RegisterField";
import DatePickerField from "../../atoms/CustomDatePicker";
import RadioButtonsFormik from "../../atoms/RadioButtonsFormik";
import { createUser } from "../../../api";
import contenedorNeon from "../../assets/Imagenes/neon.png";

const RegisterStep3 = ({ setActiveSection, setMailPhone, mailPhone  }) => {
  const navigate = useNavigation();

  const initialValues = {
    nombre: "", 
    apellido: "", 
    fecha_nacimiento: "", 
    genero: "No_especifico",
    email: mailPhone || null ,
    telefono: mailPhone || null, 
    password:"",
    password2:"",
  }

  const handleSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);

    const { password2, ...filteredValues } = values;
  
    createUser(filteredValues)
      .then((response) => {
        toast.success("Usuario creado")
        setSubmitting(false);
      })
      .catch((error) => {
        console.error("Error creating user:", error);
        setSubmitting(false); 
      });
      navigate.navigateToHome();
  };

  const handleBack = () => {
    setActiveSection("2");
  };

  const options = [
    { value: "Hombre", label: "Hombre" },
    { value: "Mujer", label: "Mujer" },
    { value: "No_especifico", label: "No seleccionar" }
  ];

  const validate = (values) => {
    const errors = {};
    if (!values.nombre) {
      errors.nombre = "Campo requerido";
    } else if (!/^[a-zA-Z-ñ\s]+$/.test(values.nombre)) {
      errors.nombre = "Solo se permiten letras y espacios";
    }
    if (!values.apellido) {
      errors.apellido = "Campo requerido";
    } else if (!/^[a-zA-Z-ñ\s]+$/.test(values.apellido)) {
      errors.apellido = "Solo se permiten letras y espacios";
    }
    if (!values.password) {
      errors.password = "Campo requerido";
    } else if (!/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{6,}/.test(values.password)) {
        errors.password = "6 caracteres, una mayúscula, una minúscula y un número.";
    }
    if (!values.password2) {
      errors.password2 = "Campo requerido";
    } else if (values.password !== values.password2) {
        errors.password2 = "Las contraseñas no coinciden";
    }
    if (!values.fecha_nacimiento) {
      errors.fecha_nacimiento = "Campo requerido";
    }
    if (!values.genero) {
      errors.genero = "Campo requerido";
    }
    return errors;
  };

  return (
    <div className="w-full sm:w-[40%] h-screen flex flex-col items-center py-8 sm:py-2 px-6 sm:px-0">
      <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit, isSubmitting }) => (
          <form
            onSubmit={handleSubmit}
            className="w-full flex flex-col justify-center items-center space-y-2"
          >
            <img src={contenedorNeon} alt="contenedor" className="w-[70%]" />
            <LargeText text="Completa tus datos *" textBold={true} />
            <RegisterField name="nombre" type="text" placeholder="Nombre(s)" />
            <RegisterField name="apellido" type="text" placeholder="Apellido(s)" />
            <RegisterField name="password" type="password" placeholder="*****" />
            <RegisterField name="password2" type="password" placeholder="****" />
            <Field name="fecha_nacimiento" as={DatePickerField} />
            <RadioButtonsFormik name="genero" title="Género" options={options} />
            <ButtonForm
              text="Continuar"
              bgColor="bg-[#578E76]"
              type="submit"
              disabled={isSubmitting}
            />
            <FaArrowLeftLong
              className="text-yellow-300 w-24 h-6 mt-4 cursor-pointer"
              onClick={handleBack}
            />
          </form>
        )}
      </Formik>
    </div>
  );
};

export default RegisterStep3;
