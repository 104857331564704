import React from "react";
import LargeText from "../../atoms/LargeText";
import ButtonForm from "../../atoms/ButtonForm";
import Modal from "../Modal";
import useModal from "../../Hooks/useModal";
import ModalComprobante from "../../atoms/ModalComprobante";

const CardHistorialPedidos = ({imagen, titulo, fecha, total, cantidad}) =>{
    const { isOpen, openModal, closeModal } = useModal();

    return(
        <div className="w-full border-2 border-yellow-300 rounded-md my-2 h-fit sm:h-40 flex flex-row items-center ">
            <div className="w-1/3 h-fit sm:h-full sm:border-r-2 border-yellow-300 flex justify-center items-center">
                <img src={imagen} className="w-full sm:w-[75%]" alt="contenedor"  />
            </div>
            <div className="flex flex-col p-2 w-2/3 sm:w-full">
                <LargeText text={titulo} textBold size="text-2xl"/>
                <div className="flex flex-row justify-between w-1/2">
                    <LargeText text={fecha} />
                    <LargeText text={cantidad} />
                </div>
                <LargeText text={`total $ ${total}`}/>
                <div className="flex flex-col-reverse sm:flex-row w-full">
                    <ButtonForm text="Ver resumen" bgColor="bg-yellow-400" width="sm:w-52" textColor="text-black" />
                    <ButtonForm text="Imprimir comprobante" onClick={openModal} width="sm:w-64"/>
                </div>
            </div>
            {isOpen && 
                <Modal onClose={closeModal} height="h-auto">
                    <ModalComprobante onClose={closeModal}/>
                </Modal>
            }
        </div>
    );
}

export default CardHistorialPedidos;