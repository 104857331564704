import React from "react";

const FotoUsuario = ({ user }) => {
    console.log(user)
    return (
        <div className="w-full h-full flex justify-center items-center p-4">
            {user?.imagen  ? 
                (
                    <div className="w-full h-full flex flex-col items-center justify-center">
                        <img src={user.imagen} alt="Usuario" className="h-1/2" />
                        <input
                            type="file"
                            className="w-full bg-white"
                        />
                    </div>
                ):(
                    <div>
                        <input
                            type="file"
                            className="w-full h-full bg-white"
                        />
                    </div>
                )
            }
        </div>
    );
};

export default FotoUsuario;