import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { CiLocationOn } from "react-icons/ci";
import useModal from "../../Hooks/useModal";
import LargeText from "../../atoms/LargeText";
import InputField from "../../atoms/InputField";
import ButtonForm from "../../atoms/ButtonForm";
import RadioButtonsList from "../../atoms/RadioButtonsList";
import Modal from "../../molecules/Modal";
import ModalConfirm from "../../molecules/ModalConfirm";
import AgregarDireccion from "../../molecules/Guia/AgregarDireccion";
import { getAddressByUser, deleteAddress } from "../../../api";

const Direcciones = () => {
    const { openModal, isOpen, closeModal } = useModal();
    const { openModal: openAdd, isOpen: isOpenAdd, closeModal: closeAdd } = useModal();
    const [address, setAddress] = useState([]);
    const [loading, setLoading] = useState(true);
    const [addressIdToDelete, setAddressIdToDelete] = useState(null);
    const [selectedOption, setSelectedOption] = useState(null);

    const handleOptionChange = (event) => {
        setSelectedOption(Number(event.target.value));
    };

    const fetchAddress = async () => {
        try {
            const AddressData = await getAddressByUser();
            setAddress(AddressData);
            if (AddressData.length > 0) {
                setSelectedOption(AddressData.find(direccion => direccion.status)?.id || null);
            }
            setLoading(false);
        } catch (error) {
            console.error("Error al obtener las direcciones", error);
            toast.error("Error al obtener las direcciones");
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchAddress();
    }, []);

    if (loading) {
        return <div className="w-screen h-screen bg-black bg-opacity-50 flex items-center justify-center">Loading...</div>;
    }

    const options = address.map(direccion => ({
        value: direccion.id,
        label: `${direccion.calle}, ${direccion.colonia}, ${direccion.codigo_postal}, ${direccion.municipio}`
    }));

    const handleDeleteClick = (id) => {
        setAddressIdToDelete(id);
        openModal();
    };

    const handleDeleteConfirm = async () => {
        if (addressIdToDelete) {
            try {
                onConfirm();
                await deleteAddress(addressIdToDelete);
            } catch (error) {
                toast.error("Error al eliminar la dirección");
            }
            fetchAddress();
        }
    };

    const onRefetch = () => {
        fetchAddress();
    }

    const onConfirm = () => {
        setAddressIdToDelete(null);
        onRefetch();
        closeModal();
        toast.success("Dirección eliminada correctamente");
    };

    const handleModalClose = () => {
        closeModal();
        setAddressIdToDelete(null);
    };

    return (
        <div className="w-full h-fit sm:h-full overflow-scroll bg-gray-700 rounded-md p-2 sm:p-8 mb-4">
            <LargeText text="Direcciones guardadas" textBold colorText="text-yellow-300" />
            <div className="space-y-4 overflow-scroll">
                <InputField etiqueta="Ubicación automática" placeholder="Escribe la dirección de entrega" icon={<CiLocationOn className="h-8 w-8 text-white" />} />
                <RadioButtonsList
                    name="direcciones"
                    options={options}
                    selectedOption={selectedOption}
                    onChange={handleOptionChange}
                />
                <div className="w-full flex flex-col sm:flex-row sm:space-x-4">
                    <ButtonForm text="Agregar ubicación manualmente" onClick={openAdd} bgColor="bg-yellow-300" textColor="text-black" />
                    <ButtonForm text="Actualizar" width="w-full sm:w-28" onClick={onRefetch} bgColor="bg-green-600" />
                    <ButtonForm text="Eliminar" width="w-full sm:w-28" onClick={() => handleDeleteClick(selectedOption)} bgColor="bg-transparent border-2 border-white" />
                </div>
            </div>
            {isOpen &&
                <ModalConfirm onClose={handleModalClose} onConfirm={handleDeleteConfirm} />
            }
            {isOpenAdd &&
                <Modal onClose={closeAdd} title="Agregar dirección" height="h-auto">
                    <AgregarDireccion onClose={closeAdd} refetch={fetchAddress} />
                </Modal>
            }
        </div>
    );
}

export default Direcciones;
