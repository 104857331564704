import axios from "axios";

const api = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	withCredentials: true,
	headers: { "Content-Type": "application/json" },
});

export const GET_ORDERS_CAJA = async () => {
	try {
		const response = await api.get("/api/ordersForCashier");
		return response.data;
	} catch (error) {
		console.error("Error fetching orders:", error);
		throw error;
	}
};

export const GET_ORDER_BY_ID = async (id) => {
	try {
		const response = await api.get(`/api/orderHistory/${id}`);
		return response.data;
	} catch (error) {
		console.error("Error al obtener la orden:", error);
		throw error;
	}
};

export const CREATE_ORDER_HISTORY = async (orderData) => {
	try {
		const response = await api.post("/api/orderHistory", orderData);
		return response.data;
	} catch (error) {
		console.error("Error al crear la orden:", error);
		throw error;
	}
};

export const DELETE_ORDER = async (id) => {
	try {
		const response = await api.delete(`/api/order/${id}`);
		return response.status;
	} catch (error) {
		console.error("Error al eliminar la orden:", error);
		throw error;
	}
};

export const GET_HISTORIAL = async () => {
	try {
		const response = await api.get("/api/ordersHistory");
		return response.data;
	} catch (error) {
		console.error("Error fetching historial:", error);
		throw error;
	}
};

export const GET_CAJA_SESSION = async () => {
	try {
		const response = await api.get("/api/cashierSession");
		return response.data;
	} catch (error) {
		console.error("Error al obtener la caja", error);
		throw error;
	}
};
