import React, { useState, useEffect } from "react";
import Circle from "../../atoms/Circle";
import { getKitchen } from "../../../api";

const CirclesAbout = () => {
  const [circles, setCircles] = useState([]);

  const getRandomCircles = (cocinas) => {
    const shuffledCocina = [...cocinas].sort(() => 0.5 - Math.random());
    return shuffledCocina;
  };

  useEffect(() => {
    const fetchKitchens = async () => {
      try {
        const kitchens = await getKitchen();
        const initialCircles = getRandomCircles(kitchens).slice(0, window.innerWidth < 640 ? 3 : 5);
        setCircles(initialCircles);
      } catch (error) {
        console.error('Error al obtener las cocinas de la base de datos', error);
      }
    };

    fetchKitchens();

    const shuffleInterval = setInterval(() => {
      setCircles((prevCircles) => getRandomCircles(prevCircles));
    }, 5000);

    return () => {
      clearInterval(shuffleInterval);
    };
  }, []);

  return (
    <div className="w-full h-full relative flex flex-row items-top justify-around transition-opacity duration-500 ease-in-out">
      {circles.map((circle) => (
        <Circle
          key={circle.id}
          cocina={circle}
          Width="w-20 sm:w-40"
          Height="h-20 sm:h-40"
        />
      ))}
    </div>
  );
};

export default CirclesAbout;
