import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import moment from "moment";
import LargeText from "../atoms/LargeText";
import useModal from "../Hooks/useModal";
import ButtonForm from "../atoms/ButtonForm";
import ModalAdmin from "../adminMolecules/ModalAdmin";
import { GET_WAITERS } from "../../apiAdmin";
import { GET_HISTORIAL_PADEL } from "../../apiPadel";
import TicketPadel from "../CajaPadel/TicketPadel";
import FilterHistorialPadel from "./FilterHistorialPadel";
import ProductosPadel from "./ProductosPadel";

const HistorialPadel = () => {
    const { openModal, isOpen, closeModal } = useModal();
    const [historial, setHistorial] = useState([]);
    const [historialCompleto, setHistorialCompleto] = useState([]);
    const [orden, setOrden] = useState(null);
    const [row, setRow] = useState(null);
    const [loading, setLoading] = useState(false);
    const [dataWaiters, setDataWaiters] = useState([]);

    const today = moment().format('YYYY-MM-DD');

    const getHistorial = async () => {
        try {
            setLoading(true);
            const historialData = await GET_HISTORIAL_PADEL();
            const waitersData = await GET_WAITERS();

            const filteredHistorial = historialData.filter(item => {
                const itemDate = moment(item.createdAt).format('YYYY-MM-DD');
                return itemDate === today;
            });

            setHistorial(filteredHistorial);
            setHistorialCompleto(historialData); 
            setDataWaiters(waitersData);
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getHistorial();
    }, []);

    const handleOpen = (orden, row) => {
        setOrden(orden);
        setRow(row);
        openModal();
    };

    const handleClose = () => {
        setOrden(null);
        setRow(null);
        closeModal();
    };

    const getWaiterName = (id_mesero) => {
        const waiter = dataWaiters.find(waiter => waiter.id === id_mesero);
        return waiter ? waiter.nombre : "Desconocido"; 
    };

    const calculateTotalGanancia = () => {
        return historial.reduce((total, order) => total + parseFloat(order.total_general), 0).toFixed(2);
    };

    const columns = [
        { name: "ID", selector: row => row.id, sortable: true },
        { name: "Metodo de pago", selector: row => row.metodo_pago, sortable: true },
        { name: "Total", selector: row => `$${row.total_general} MX`, },
        { name: "Efectivo", selector: row => `$${row.total_efectivo} MX`, },
        { name: "Tarjeta", selector: row => `$${row.total_tarjeta} MX`, },
        { name: "Mesa", selector: row => row.num_mesa, },
        { name: "Mesero", selector: row => getWaiterName(row.id_mesero), sortable: true },
        { name: "Fecha", selector: row => moment(row.createdAt).format("DD/MM/YYYY HH:mm"), sortable: true },
        {
            name: "Acciones",
            selector: row => (
                <div>
                    <ButtonForm text="Ver" bgColor="bg-green-600" width="w-16" onClick={() => handleOpen(row.orden, row)} />
                </div>
            ),
        },
    ];

    const NoDataComponent = () => (
        <div className="h-[75vh] flex justify-center items-center">
            <LargeText text="No hay pedidos en el historial" colorText="text-red-500" size="text-xl md:text-5xl" textBold />
        </div>
    );

    return (
        <div className="w-full border-2 border-gray-200 rounded-lg overflow-x-auto">
            <div className="flex flex-row justify-center items-center space-x-2 md:space-x-4 p-2">
                <LargeText text="Historial de pedidos" textAlign="text-center" size="text-xl md:text-2xl" textBold colorText="" onClick={getHistorial} />
                <ButtonForm text="Actualizar" onClick={getHistorial} bgColor="border border-black hover:border-0 hover:bg-yellow-300" textColor="text-black" width="w-24" />
            </div>
            <FilterHistorialPadel historialOriginal={historialCompleto} setHistorial={setHistorial} today={today} />
            <DataTable
                data={historial}
                columns={columns}
                progressPending={loading} noDataComponent={<NoDataComponent />}
                pagination paginationPerPage={5} paginationRowsPerPageOptions={[5,10, 20, 50, 100]}
            />
            <div className="flex justify-end p-4">
                <LargeText text={`Ganancia Total: $${calculateTotalGanancia()} MX`} size="text-2xl ml-auto" textBold colorText="text-red-500" />
            </div>
            <ProductosPadel historial={historial} />
            {isOpen && orden !== undefined && row !== null &&
                <ModalAdmin title="Pedido" onClose={handleClose}>
                    <TicketPadel id={row.id} />
                </ModalAdmin>
            }
        </div>
    );
};

export default HistorialPadel;
