import logo from "../assets/logos/logo.jpg"

export const Cocina = [
        {
            id:1,
            nombre:"MUNCHBURGER",
            imagen:logo,
            background_color:"#c2410c",
            border_color:"#f97316",
            text_color:"#f97316",
            props:"#f97316",
            shadow_color:"#f97316"
    },
    {   
        id:2,
        nombre:"LATLAYU",
        imagen: logo,
        background_color: "#be185d",
        border_color:"#DB3C81",
        text_color:"t#DB3C81",
        props:"#f472b6",
        shadow_color:"#DB3C81"
    },      
    {
            id:3,
            nombre:"GRILLIO",
            imagen:logo,
            background_color:"#991b1b",
            border_color:"#C32C28",
            text_color:"#C32C28",
            props:"#f87171",
            shadow_color:"#C32C28"
    },
    {
            id:4,
            nombre:"LA MAISON SUCREE",
            imagen: logo,
            background_color:"#b91c1c",
            border_color:"#ED3833",
            text_color:"#ED3833",
            props:"#f87171",
            shadow_color:"#ED3833"
    },
    {
            id:5,
            nombre:"PIBILERIA",
            imagen: logo,
            background_color:"#9d174d",
            border_color:"#D96690",
            text_color:"#D96690",
            props:"#f472b6",
            shadow_color:"#D96690"
    },
    {
        id:6,
        nombre:"GREEN BOWL",
        imagen:logo,
        background_color:"#347C5F",
        border_color:"#347C5F",
        text_color:"#347C5F",
        props:"#10b981",
        shadow_color:"#347C5F"
}, 
{
        id:7,
        nombre:"AURA COCTAILS",
        imagen:logo,
        background_color:"#000000",
        border_color:"#eab308",
        text_color:"#eab308",
        props:"#eab308",
        shadow_color:"#eab308"
}, 
{
        id:8,
        nombre:"AMALFI",
        imagen:logo,
        background_color:"#84cc16",
        border_color:"#84cc16",
        text_color:"#ffffff",
        props:"#84cc16",
        shadow_color:"#C32C28"
}, 
{
        id:9,
        nombre:"WOKCHAUFA",
        imagen:logo,
        background_color:"#7f1d1d",
        border_color:"#dc2626",
        text_color:"#ef4444",
        props:"#ef4444",
        shadow_color:"#dc2626"
}, 
{
        id:10,
        nombre:"KAI",
        imagen:logo,
        background_color:"#fca5a5",
        border_color:"#fca5a5",
        text_color:"#fca5a5",
        props:"#f87171",
        shadow_color:"#fca5a5"
}, 
]