import React from "react";
import { Field } from "formik";

const RadioButtonsFormik = ({ name, options, title = "" }) => {
  return (
    <div className="w-full">
      <label htmlFor={name} className="text-gray-400 text-md">
        {title}
      </label>
      <div className="w-full flex flex-row justify-between text-white p-2">
        {options.map((option, index) => (
          <label key={index}>
            <Field
              type="radio"
              name={name}
              value={option.value}
              className="m-2"
            />
            {option.label}
          </label>
        ))}
      </div>
    </div>
  );
};

export default RadioButtonsFormik;
