import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useNavigation } from "../Hooks/useNavigation";
import { useProducts } from "../../context/ProductContext";
import useModal from "../Hooks/useModal";
import { FaFacebookF } from "react-icons/fa6";
import { GiShoppingCart } from "react-icons/gi";
import { IoLocationOutline } from "react-icons/io5";
import { CgCloseR, CgMenuRight } from "react-icons/cg";
import { FaInstagram, FaTiktok, FaWhatsapp } from "react-icons/fa";
import Link from "../atoms/Link";
import Button from "../atoms/Button";
import LargeText from "../atoms/LargeText";
import ModalCarrito from "../molecules/Carrito/ModalCarrito";
import con from "../assets/logos/logo.jpg"
import contenedorNeon from "../assets/Imagenes/neon.png";

function Header() {
  const { allProducts } = useProducts();
  const navigate = useNavigation();
  const { isOpen, openModal, closeModal } = useModal();
  const [menuOpen, isOpenMenu] = useState(false);
  const productCount = allProducts.length;
  const location = useLocation();

  const handleCambiar = (value) => {
    if (value === "navigateToHome") {
      navigate.navigateToHome();
    } else if (value === "navigateToCocinasSection") {
      navigate.navigateToCocinasSection();
    }
    isOpenMenu(false);
  };

  return (
      <div className="fixed sm:static top-0 left-0 w-screen h-14 bg-[#006437] bg-opacity-65 sm:bg-opacity-100 flex flex-row items-center justify-end z-30">
        <Link value="Inicio" onClick={navigate.navigateToHome} show="hidden sm:flex" />
        <Link value="Cocinas" onClick={navigate.navigateToCocinasSection} show="hidden sm:flex"/>
        <Link value="Guia" onClick={navigate.navigateToGuia} show="hidden sm:flex" />
        <Link value="Configuración" onClick={navigate.navigateToConfig} show="hidden sm:flex" />
        <CgMenuRight className="flex sm:hidden text-white h-10 w-10 "  onClick={()=>isOpenMenu(true)} />
        {productCount > 0 && !isOpen ? (
          <Link
            icon={<GiShoppingCart className="w-10 h-10 flex" />}
            onClick={isOpen === true ? closeModal : openModal}
            status={isOpen}
            productCount={productCount}
          />
        ) : (
          <Link
            icon={<GiShoppingCart className="w-10 h-10 flex" />}
            onClick={isOpen === true ? closeModal : openModal}
            status={isOpen}
            productCount={productCount}
          />
        )}      
        {isOpen && (
        <ModalCarrito
          onClose={closeModal}
        />
      )}
      { menuOpen === true && isOpen === false &&
        <div className="fixed top-0 right-0 h-screen w-[90%] bg-black bg-opacity-90 flex flex-col justify-around items-center rounded-l-3xl px-4" >
          <div className="w-full flex justify-between items-center">
            <img src={con} className="h-12" alt="Logo" />
            <CgCloseR className="w-10 h-10 text-yellow-300" onClick={()=>isOpenMenu(false)} />
          </div>
          <div className="w-full flex flex-col justify-center items-center space-y-4 pb-4 border-b-2 border-yellow-300 ">
            <Button text="Inicio" largeMargin={location.pathname === "/home" ? (true) : (false) } onClick={() => handleCambiar("navigateToHome")} />
            <Button text="Cocinas" largeMargin={location.pathname === "/home" ? (true) : (false)} onClick={() => handleCambiar("navigateToCocinasSection")} />
            <Button text="Guia" largeMargin={location.pathname === "/guia" ? (true) : (false)} onClick={navigate.navigateToGuia} />
            <Button text="Configuración" largeMargin={location.pathname === "/config" ? (true) : (false)} onClick={navigate.navigateToConfig} />
          </div>
          <div className="w-full flex flex-col space-y-4">
            <LargeText text="Redes sociales" colorText="text-yellow-300" textAling="text-center" textBold/>
            <div className=" w-full flex flex-row ">
              <div className="w-1/2 flex flex-row justify-around" >
                <FaFacebookF className="h-8 w-8 bg-blue-700 text-white pt-1.5 rounded-full" />
                <FaInstagram className="h-8 w-8 p-1 rounded-full bg-gradient-to-tr from-pink-500 via-pink-500 to-yellow-300 text-white"/>
                <FaTiktok className="h-8 w-8 text-white bg-black p-1 rounded-full"/>
              </div>
              <div className="w-1/2 flex flex-row justify-around items-center" >
                <FaWhatsapp color="white" size={30}/>
                <IoLocationOutline color="white" size={30} />
              </div>
            </div>
          </div>
          <img src={contenedorNeon} className="w-3/4" alt="Contenedor" />
        </div>
      }
      
      </div>
  );
}

export default Header;
