import React, { useState } from "react";
import RadioButtonsList from "../../atoms/RadioButtonsList";
import { LiaMoneyBillWaveAltSolid } from "react-icons/lia";
import { CiCreditCard1 } from "react-icons/ci";

const CambiarPago = ({onClose, Usuario}) =>{
    const [selectedOption, setSelectedOption] = useState(
        Usuario.pagos.find(pago => pago.main).id
    );

    const handleOptionChange = (event) => {
        setSelectedOption(Number(event.target.value));
    };

    const options = Usuario.pagos.map(pago => ({
        value: pago.id,
        label: pago.tipo === "efectivo" ? "Efectivo" : `Tarjeta terminada en ${String(pago.numero).slice(-4)}`,
        icon: pago.tipo === "efectivo" ? <LiaMoneyBillWaveAltSolid className="w-6 h-6" /> : <CiCreditCard1 className="w-6 h-6" />
    }));

    return(
        <div>
            <RadioButtonsList
                name="pagos"
                options={options}
                selectedOption={selectedOption}
                onChange={handleOptionChange}
            />
        </div>
    );
}

export default CambiarPago;