import React from "react";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { Route, Routes } from 'react-router-dom';
import { ProductProvider } from "./context/ProductContext";
import { SessionProvider } from "./context/SessionContext";
import { MusicPlayeProvider } from "./context/MusicPlayerContext"
import Guia from "./components/pages/Guia";
import Menu from "./components/pages/Menu";
import Login from "./components/pages/Login";
import Padel from "./components/Padel/Padel";
import Bar from "./components/adminPages/Bar";
import VipPage from "./components/pages/VipPage";
import Users from "./components/adminPages/Users";
import Cajas from "./components/adminPages/Cajas";
import HomePage from "./components/pages/HomePage";
import HomeUser from "./components/pages/HomeUser";
import Register from "./components/pages/Register";
import Admins from "./components/adminPages/Admins";
import AboutPage from "./components/pages/AboutPage";
import Meseros from "./components/adminPages/Meseros";
import CajaMain from "./components/cajaPages/CajaMain";
import Layout from "./components/adminOrganisms/Layout";
import MenuPage from "./components/adminPages/MenuPage";
import Kitchens from "./components/adminPages/Kitchens";
import Almacenes from "./components/adminPages/Almacenes";
import BarDetail from "./components/adminPages/BarDetail";
import MenuProducts from "./components/pages/MenuProducts";
import Configuration from "./components/pages/Configuration";
import LayoutCaja from "./components/CajaOrganism/LayoutCaja";
import MenuPageBar from "./components/adminPages/MenuPageBar";
import PerfilAdmnK from "./components/admKitPages/PerfilAdmK";
import PedidosAdmK from "./components/admKitPages/PedidosAdmK";
import CajaMainPage from "./components/CajaPadel/CajaMainPage";  
import PedidosAdmin from "./components/adminPages/PedidosAdmin";
import MenuViewAdmk from "./components/admKitPages/MenuViewAdmk";
import KitchensUsers from "./components/adminPages/KitchensUsers";
import KitchenDetail from "./components/adminPages/KitchenDetail";
import LayoutCocina from "./components/admKitOrganisms/LayoutCocina";
import LayoutCajaPadel from "./components/CajaPadel/LayoutCajaPadel";
import HistorialPedidos from "./components/cajaPages/HistorialPedidos";
import AdminsKitchensUsers from "./components/adminPages/AdminsKitchensUsers";
import HistorialPedidosPage from "./components/CajaPadel/HistorialDePedidosPage";

function App() {
  return (
    <SessionProvider>
      <ProductProvider>
        <MusicPlayeProvider>
          <Routes>
            {/* General */}
            <Route path="/" element={<HomePage/>}/>
            <Route path="/menu" element={<Menu/>}/>
            <Route path="/login" element={<Login/>}/>
            <Route path="/vip" element={<VipPage/>}/>
            <Route path="/about" element={<AboutPage/>}/>
            <Route path="/register" element={<Register/>}/>
            <Route path="/menu/:kitchenId" element={<MenuProducts/>}/>
            {/* Usuario */}
            <Route path="/guia" element={<Guia/>}/>
            <Route path="/home" element={<HomeUser/>}/>
            <Route path="/config" element={<Configuration/>}/>
            {/* Administrador */}
            <Route path="/users" element={<Layout><Users/></Layout>}/>
            <Route path="/cajas" element={<Layout><Cajas/></Layout>}/>
            <Route path="/Padel" element={<Layout><Padel/></Layout>}/>
            <Route path="/barAdmin" element={<Layout><Bar/></Layout>}/>
            <Route path="/Admins" element={<Layout><Admins/></Layout>}/>
            <Route path="/meseros" element={<Layout><Meseros/></Layout>}/>
            <Route path="/kitchens" element={<Layout><Kitchens/></Layout>}/>
            <Route path="/almacenes" element={<Layout><Almacenes/></Layout>}/>
            <Route path="/PedidosAdmin" element={<Layout><PedidosAdmin/></Layout>}/>
            <Route path="/UsuariosCocinas" element={<Layout><KitchensUsers/></Layout>}/>
            <Route path="/barAdmin/:kitchenId" element={<Layout><BarDetail/></Layout>}/>
            <Route path="/kitchens/menu/:kitchenId" element={<Layout><MenuPage/></Layout>}/>
            <Route path="/kitchens/:kitchenId" element={<Layout><KitchenDetail/></Layout>}/>
            <Route path="/barAdmin/menu/:kitchenId" element={<Layout><MenuPageBar/></Layout>}/>
            <Route path="/UsuariosAdminCocinas" element={<Layout><AdminsKitchensUsers/></Layout>}/>
            {/* Administrador de cocina */}
            <Route path="/pedidos" element={<LayoutCocina><PedidosAdmK/></LayoutCocina>}/>
            <Route path="/menuAdmin" element={<LayoutCocina><MenuViewAdmk/></LayoutCocina>}/>
            <Route path="/perfilCocina" element={<LayoutCocina><PerfilAdmnK/></LayoutCocina>}/>
            {/* Caja */}
            <Route path="/caja" element={<LayoutCaja><CajaMain/></LayoutCaja>}/>
            <Route path="/historialPedidos" element={<LayoutCaja><HistorialPedidos/></LayoutCaja>}/>
            {/* Caja Padel */}
            <Route path="/cajaPadel" element={<LayoutCajaPadel><CajaMainPage/></LayoutCajaPadel>}/>
            <Route path="/historialPedidosPadel" element={<LayoutCajaPadel><HistorialPedidosPage/></LayoutCajaPadel>}/>
          </Routes>
          <ToastContainer
            position="top-left" autoClose={2000} hideProgressBar={false} newestOnTop
            closeOnClick={false} pauseOnFocusLoss draggable pauseOnHover theme="colored"
          />
        </MusicPlayeProvider>
      </ProductProvider>
    </SessionProvider>
  );
}

export default App;
