import React, { useState } from "react";
import LargeText from "../../atoms/LargeText";
import Contador from "../../atoms/Contador";
import ButtonForm from "../../atoms/ButtonForm";
import { useNavigation } from "../../Hooks/useNavigation";
import { IoIosCloseCircle } from "react-icons/io";
import { toast } from "react-toastify";
import ExtraList from "./ExtraList";
import { Formik } from "formik";
import { FaStar } from "react-icons/fa";
import { useProducts } from "../../../context/ProductContext";
import Circle from "../../atoms/Circle";

const ModalProduct = ({ item, onClose, cocina, closeCarrito }) => {
    const { allProducts, setAllProducts, total, setTotal } = useProducts();
    const navigate = useNavigation();

    const [contador, setContador] = useState(0);

    const handlePay = (product) => {
        const existingProduct = allProducts.find((item) => item.id === product.id);

        if (existingProduct) {
            const updatedProducts = allProducts.map((item) =>
                item.id === product.id ? { ...item, count: item.count + contador } : item
            );
            const addRemove = (contador * product.precio);
            setTotal(addRemove + total);
            setAllProducts(updatedProducts);

            if (existingProduct.count + contador === 0) {
                const filteredProducts = allProducts.filter(item => item.id !== product.id);
                toast.error("Producto eliminado");
                setAllProducts(filteredProducts);
            }
        } else {
            setAllProducts([...allProducts, { ...product, count: 1 + contador }]);
            toast.success("Producto agregado al carrito");
            const newTotal = total + product.precio * (product.count + contador);
            setTotal(newTotal);
        }
        navigate.navigateToGuia();
        handleClose();
    };

    const handleKeep = (product) => {
        const existingProduct = allProducts.find((item) => item.id === product.id);
        if (existingProduct) {
            const updatedProducts = allProducts.map((item) =>
                item.id === product.id ? { ...item, count: item.count + contador } : item
            );
            const addRemove = (contador * product.precio);
            setTotal(addRemove + total);
            setAllProducts(updatedProducts);

            if (existingProduct.count + contador === 0) {
                const filteredProducts = allProducts.filter(item => item.id !== product.id);
                toast.error("Producto eliminado");
                setAllProducts(filteredProducts);
            }
        } else {
            setAllProducts([...allProducts, { ...product, count: item.count + contador }]);
            toast.success("Producto agregado al carrito")
            const newTotal = total + product.precio * (product.count + contador);
            setTotal(newTotal);
        }
        handleClose();
        if (typeof closeCarrito === 'function') {
            closeCarrito();
        }
    }

    const handleClose = () => {
        onClose();
    }

    function eliminarTransparencia(hexColor) {
        if (hexColor.startsWith("#")) {
          return hexColor.substring(0, 7);
        }
        return hexColor;
      }
      
      const colorHexSinTransparencia = eliminarTransparencia(cocina.background_color);

    return (
        <main 
            style={{
                backgroundColor:cocina.background_color
            }}
            className={`fixed top-0 left-0 w-full h-full z-50 flex justify-center items-center`}>
            <div
                style={{
                    backgroundColor:colorHexSinTransparencia
                }}
                 className={`w-full sm:w-[90%] h-full sm:h-[95%] rounded-lg overflow-auto`}>
                <Formik
                    initialValues={{ extras: {} }}
                    onSubmit={(values) => {
                        handlePay(item, values);
                    }}
                >
                    {({ handleSubmit }) => (
                        <form className="w-full h-full" onSubmit={handleSubmit}>
                            <header className="w-full h-fit sm:h-1/5 bg-[#00000032] flex flex-col-reverse sm:flex-row items-center justify-around sm:justify-between text-center py-4 sm:py-0 px-0 sm:px-8">
                                <img src={cocina.imagen} className="w-1/2 sm:w-auto h-auto sm:h-[100%]" alt="Cocina Imagen" />
                                <div>
                                    <LargeText text="Detalles del producto" size="text-sm sm:text-5xl" textBold={true} />
                                    <LargeText text={`Cocina ${cocina.nombre}`} size="text-lg sm:text-5xl" textBold />
                                </div>
                                <IoIosCloseCircle size={30} className="text-white mb-4 sm:mb-0 cursor-pointer" onClick={onClose} />
                            </header>
                            <div className="flex-1 h-screen sm:h-[70%] w-full flex flex-col sm:flex-row overflow-auto">
                                <div className="w-full sm:w-1/2 h-1/2 sm:h-full flex flex-col justify-between p-6">
                                    <div className="w-full flex justify-between">
                                        <LargeText text={item.platillo} size="text-2xl sm:text-4xl" textBold />
                                        <div className="flex flex-row items-center space-x-1">
                                            <FaStar color="white" size={25} />
                                            <LargeText text="4,9 Rating"/>
                                        </div>
                                    </div>
                                    <div className="flex w-full h-full justify-center p-8">
                                        <img src={item.imagen} alt="Producto imagen" className="max-h-full max-w-full" />
                                    </div>
                                </div>
                                <div className="w-full sm:w-1/2 h-screen sm:h-full p-4 flex flex-col overflow-auto">
                                    <LargeText text={` $ ${item.precio}`} size="text-2xl" textBold />
                                    <LargeText text={item.descripcion} textBold size="text-sm" />
                                    <ExtraList
                                        item={item}
                                        cocina={cocina}
                                        allProducts={allProducts}
                                        setAllProducts={setAllProducts}
                                        total={total}
                                        setTotal={setTotal}
                                    />
                                </div>
                            </div>
                            <section className="w-full h-fit sm:h-[10%] flex flex-col sm:flex-row">
                                <div className="w-full sm:w-1/2 h-full px-8 flex items-center justify-center">
                                    <Contador
                                        width="w-32"
                                        border="border-white"
                                        product={item}
                                        isInModal={true}
                                        contador={contador}
                                        setContador={setContador}
                                    />
                                </div>
                                <div className="w-full sm:w-1/2 h-full flex flex-col sm:flex-row justify-between items-center px-4 sm:pr-4 sm:space-x-4">
                                    <ButtonForm bgColor={`bg-[${cocina.props_color}]`} text="Aceptar y seguir comprando" width="w-full sm:w-1/2" onClick={() => handleKeep(item)} />
                                    <ButtonForm bgColor={`bg-[${cocina.props_color}]`} text="Aceptar y pagar" width="w-full sm:w-1/2" onClick={() => handlePay(item)} />
                                </div>
                            </section>
                        </form>
                    )}
                </Formik>
            </div>
        </main>
    );
}

export default ModalProduct;
