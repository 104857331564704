import React, { useState, useEffect } from "react";
import Circle from "../atoms/Circle";
import { useNavigate } from "react-router-dom";
import { getKitchen } from "../../api";
import FooterMenu from "../organisms/FooterMenu";
import H2Custom from "../atoms/H2Custom";
import FondoMenu from "../assets/Imagenes/FondoMenu.jpg"
import Titulo from "../atoms/Titulo";

const Menu = () => {
    const navigate = useNavigate();
    const [cocinas, setCocinas] = useState([]);

    const fetchKitchen = async () =>{
        try{
            const data = await getKitchen();
            const filteredCocinas = data.filter(cocina => cocina.tipo !== "CocinaPadel");
            setCocinas(filteredCocinas);
        } catch (error) {
            console.log("Error al obtener los datos", error);
        }
    };

    useEffect (()=>{
        fetchKitchen();
    },[]);

    useEffect(() => {
        document.title = "Menú - Contenedor";
    }, []); 

    const handleCircleClick = (id) => {
        navigate(`${id}`);
    };

    return(
        <div>
            <div 
                className="px-4 pt-4 flex  flex-col justify-start items-center min-h-screen"
                style={{ backgroundImage:`url(${FondoMenu})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
            >
                <div className="mt-8">
                    <Titulo Letras="COCINAS CONTENEDOR" />
                </div>
                <H2Custom text="Se indican las cocinas abiertas al brillar" textBold size="text-xl" textAling="text-center" />
                <div className="w-full flex flex-wrap justify-between mt-4">
                    {cocinas.map((cocina)=>(
                        <Circle
                            key={cocina.id}
                            cocina={cocina}
                            selected={cocina.status === "Abierto" ? true : false}
                            Height="aspect-square" 
                            Width="w-[45%] md:w-[13%] md:mx-[1%] md:my-8" 
                            onClick={()=>handleCircleClick(cocina.id)}
                        />
                    ))}
                </div>
            </div>
            <FooterMenu/>
        </div>
    )
}

export default Menu;