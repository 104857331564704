import React from "react";
import { Formik } from "formik";
import ButtonForm from "../../atoms/ButtonForm";
import { toast } from "react-toastify";
import InputFieldFormik from "../../atoms/InputFieldFormik";
import { createAddress } from "../../../api";

const AgregarDireccion = ({ onClose, refetch }) =>{
    const initialValues ={
        calle: "", colonia: "", municipio: "", tipo:"", codigo_postal:"", tag:"", numero_exterior:"", numero_interior:""
      };

    const validate = (values) => {
        const errors = {};
        if (!values.calle) {
            errors.calle = "Campo requerido";
        }
        if (!values.colonia) {
            errors.colonia = "Campo requerido";
        } 
        if (!values.municipio) {
          errors.municipio = "Campo requerido";
        }
        if (!values.codigo_postal) {
            errors.codigo_postal = "Campo requerido";
        } else if (!/^\d{5}$/.test(values.codigo_postal)) {
            errors.codigo_postal = "Debe ser un código postal valido";
        }
        if (!values.numero_exterior) {
            errors.numero_exterior = "Agregar número o S/N";
        }
        return errors;
      };

    const handleSubmit = async (values, { setSubmitting } ) => {
        try {
            await createAddress(values);
            toast.success("Dirección agregada correctamente");
            onClose();
            refetch();
        } catch (error) {
            toast.error("Error al agregar la direccion");
        } finally {
            setSubmitting(false);
        }
    }

    return(
        <div className="w-full">
            <Formik
                initialValues={initialValues}
                validate={validate}
                onSubmit={handleSubmit}
            >
            {({ handleSubmit }) => (
                <form onSubmit={handleSubmit} className="w-full">
                            <InputFieldFormik
                            name="calle"
                            label="Calle"
                            type="text"
                            placeholder="Introduzca la calle"
                        />
                    <div className="w-full flex flex-col sm:flex-row sm:space-x-4">
                    <div className="flex flex-col w-full sm:w-1/2 py-2 space-y-2">
                        <InputFieldFormik
                            name="colonia"
                            label="Colonia"
                            type="text"
                            placeholder="Introduzca la colonia"
                        />
                        <InputFieldFormik
                            name="municipio"
                            label="Municipio"
                            type="text"
                            placeholder="Introduzca el municipio"
                        />
                        <InputFieldFormik
                            name="tipo"
                            label="Piso / Oficina / Apto / Dpto (opcional)"
                            type="text"
                            placeholder="Escribe el Piso / Oficina / Apto / Dpto (opcional)"
                        />
                    </div>
                    <div className="flex w-full sm:w-1/2 flex-col py-2 space-y-2">
                        <InputFieldFormik
                            name="codigo_postal"
                            label="Código postal"
                            type="number"
                            placeholder="Introduzca el código postal"
                        />
                        <div className="w-full flex flex-row space-x-2 "> 
                            <InputFieldFormik
                                name="numero_exterior"
                                label="Numero exterior"
                                placeholder="Num ext"
                            />
                            <InputFieldFormik
                                name="numero_interior"
                                label="Numero interior"
                                placeholder="Num int"
                            />
                        </div>
                        <InputFieldFormik
                            name="tag"
                            label="Etiqueta (opcional)"
                            type="text"
                            placeholder="Introduzca la etiqueta"
                        />
                    </div>
                    </div>
                    <ButtonForm text="Siguiente" bgColor="bg-yellow-300" type="submit" textColor="text-black mt-4" />
                </form>
            )}
            </Formik>
        </div>
    );
}

export default AgregarDireccion;