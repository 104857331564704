import React from "react";
import LargeText from "../../atoms/LargeText";

const CardProducto = ({item}) =>{
    return(
        <div className="w-full flex flex-row justify-between my-4 pr-8">
            <div className="w-full sm:w-auto flex flex-row space-x-6 h-20">
                <img src={item.imagen} className="h-full sm:h-3/4" alt="Card producto"/>
                <div className="w-full">
                    <LargeText text={item.platillo}textBold/>
                    <LargeText text={`$ ${item.precio}`} textBold/>
                    <LargeText text={`${item.count} unidad(es)`}  size="text-base flex sm:hidden" />
                </div>
            </div>
            <LargeText text={`${item.count} unidad(es)`}  size="text-base hidden sm:flex" />
        </div>
    );
}

export default CardProducto;