import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import useModal from "../Hooks/useModal";
import { useNavigation } from "../Hooks/useNavigation";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import "./styles.css";
import Button from "../atoms/Button";
import LargeText from "../atoms/LargeText";
import CardsAbout from "../molecules/About/CardsAbout";
import CirclesAbout from "../molecules/About/CirclesAbout";
import Footer from "../organisms/Footer";
import HeaderRegister from "../organisms/HeaderRegister";
import face from "../assets/Imagenes/face.png";
import chef from "../assets/Imagenes/chef.png";
import carro from "../assets/Imagenes/carro.png";
import insta from "../assets/Imagenes/insta.png";
import tiktok from "../assets/Imagenes/tiktak.png";
import plant from "../assets/Imagenes/plantAbout.png";
import contenedorNeon from "../assets/Imagenes/neon.png";
import arrowAbout from "../assets/Imagenes/arrowAbout.png";
import platoBot from "../assets/Imagenes/platoAboutBot.png";
import platoMid from "../assets/Imagenes/platoAboutMid.png";
import platoTop from "../assets/Imagenes/platoAboutTop.png";
import fondoChamos from "../assets/Imagenes/fondoChamos.jpg";
import arrowAbout2 from "../assets/Imagenes/arrowAbout2.png";
import platoSec3 from "../assets/Imagenes/platoAboutSec3.png";

const AboutPage = () => {
    const { isOpen, openModal, closeModal } = useModal();
	const location = useLocation();
	const { navigateToServicesSection } = useNavigation();

	useEffect(() => {
		if (location.hash === "#section-services") {
			const scrollToServicesSection = () => {
			  const sectionServices = document.getElementById("section-services");
			  if (sectionServices) {
				sectionServices.scrollIntoView({ behavior: "smooth" });
			  }
			};
	  
			scrollToServicesSection();
		  } else if (location.hash ==="top"){
            const scrollToTop = () => {
                const section= document.getElementById("top");
                if (section) {
                  section.scrollIntoView({ behavior: "smooth" });
                }
              };
              scrollToTop();
        } else {
        const scrollToSec = () => {
          const section= document.getElementById("#");
          if (section) {
            section.scrollIntoView({ behavior: "smooth" });
          }
        };
        scrollToSec();
        }
    }, [location.hash]);

	useEffect(() => {
        document.title = "Bienvenidos - Contenedor";
    }, []); 

	return (
		<div className="w-screen relative " id="top">
			<HeaderRegister/>
				<div className="animation-container">
					<div className="leaf leaf1" /><div className="leaf leaf2" /><div className="leaf leaf3" /><div className="leaf leaf4" /><div className="leaf leaf5" /><div className="leaf leaf6" /><div className="leaf leaf7" /><div className="leaf leaf8" />
					<div className="leaf leaf1" /><div className="leaf leaf2" /><div className="leaf leaf3" /><div className="leaf leaf4" /><div className="leaf leaf5" /><div className="leaf leaf6" /><div className="leaf leaf7" /><div className="leaf leaf8" />
				</div>
			<section className="h-[75vh] sm:h-screen w-full flex flex-row">
				<div className="w-1/3 sm:w-1/2 bg-[#006437] h-full relative" id="#">
					<div className="w-[40%] h-[4%] top-[68%] absolute hidden sm:flex border-yellow-500 border-r-4 border-b-4"/>
					<img
						src={platoTop} alt="PLATO"
						className="absolute w-full sm:w-[80%] left-[10%] sm:left-0 z-10"
					/>
					<div className="w-[50%] sm:w-[20%] h-[30%] sm:h-[13%] left-[61%] sm:left-[87%] top-[35%] sm:bottom-[53%] absolute z-0 border-yellow-500 border-t-4 border-l-4"/>
					<img
						src={platoMid} alt="PLATO"
						className="w-full sm:w-[55%] right-[30%] sm:left-[60%] bottom-[30%] sm:bottom-[14%] absolute"
					/>
					<div className="w-[15%] h-[26%] left-[11%] top-[95%] absolute hidden sm:flex border-yellow-500 border-l-4 border-t-4"/>
					<img
						src={platoBot} alt="PLATO"
						className="w-full sm:w-[60%] top-[68%] sm:top-[70%] left-[12%] sm:left-[20%] absolute"
					/>
				</div>
				<div className="w-2/3 sm:w-1/2 flex flex-col content-center justify-center p-4 sm:p-16 relative">
					<LargeText text="Bienvenidos a" textBold={true} size="text-sm sm:text-base" />
					<img src={contenedorNeon} width={"100%"} alt="LOGO" />
					<LargeText
						text="Imagina un lugar donde tus sentidos se deleita con una explosión de sabores, mientras disfrutas de un ambiente vibrante y lleno de vida. 
                        En Contenedores, te ofrecemos una experiencia gastronómica única, combinando lo mejor de la cocina internacional con un toque local,
                        todo en un solo lugar."
						textAling="text-justify hidden sm:flex"
					/>
					<LargeText 
						text="Imagina un lugar donde tus sentidos se deleita con una explosión de sabores, mientras disfrutas de un ambiente vibrante y lleno de vida." 
						textAling="text-justify flex sm:hidden"
						size="text-sm"
					/>
					<MdOutlineKeyboardArrowDown className="absolute bottom-[5%] left-[80%] text-white hidden sm:flex cursor-pointer" size={40} onClick={navigateToServicesSection}/>
					<div className="w-[25%] h-[25%] top-[90%] left-[25%] absolute flex sm:hidden border-yellow-500 border-r-4 border-b-4 " />
				</div>
			</section>
			<section className="h-screen w-full bg-black pt-[10%]" id="section-services">
				<div className="w-full pl-4 md:pl-24 h-1/3">
					<LargeText text="¿Que encontraras? en" textBold={true} size="text-sm md:text-2xl"/>
					<img src={contenedorNeon} className="w-[45%] md:w-[40%] " alt="LOGO" />
				</div>
				<div className="w-full h-2/3 p-0 sm:p-4">
					<CardsAbout />
				</div>
			</section>
			<section className="h-screen w-full flex relative">
				<div className="w-2/3 sm:w-1/2 h-full flex items-center absolute sm:relative">
					<img src={platoSec3} alt="PLATO" className="w-full mt-[10%] rotate-45 opacity-30 md:opacity-100 " />
				</div>
				<div className=" w-full md:w-1/2 h-full relative flex flex-col items-center sm:items-end justify-center text-end px-8 space-y-6">
					<img src={contenedorNeon} alt="LOGO" />
					<LargeText
						textAling="text-rigth"
						text="es más que un lugar para comer; es una aventura culinaria y sensorial. 
                        Ven y descubre por qué somos el destino favorito de los amantes de la buena comida y la buena música. 
                        ¡Te esperamos para compartir momentos inolvidables!"
					/>
					<div className="w-[35%] h-[1] right-[102.3%] bottom-[16.5%] absolute hidden sm:flex border-yellow-500 border-t-4 border-l-4"/>
					<div className="w-[55%] h-[10%] absolute bottom-[17%] right-[48%] hidden sm:flex border-yellow-500 border-t-4 border-l-4"/>
                    <Button text="¡No te quedes fuera!"  onClick={openModal} largeMargin={true} />
				</div>
			</section>
			<section
				className="h-fit sm:h-screen w-full flex flex-col sm:flex-row"
				style={{
					backgroundImage: `linear-gradient(to right, rgba(0, 0, 255, 0.5), rgba(0, 0, 255, 0.5)), url(${fondoChamos})`,
					backgroundSize: "cover",
					backgroundRepeat: "no-repeat",
					backgroundPosition: "center",
				}}
			>
				<div className="w-full sm:w-2/3 h-fit sm:h-full flex flex-col justify-center sm:justify-left text-2xl sm:text-5xl items-center sm:items-start p-4 sm:pl-12 relative">
					<p className="font-chamos text-white">!Descubre las</p>
					<p className="font-chamos text-[#FFE500]">
						mejores micheladas <br /> y preparados
					</p>
					<p className="font-chamos text-[#FF00F5]">de la ciudad!</p>
					<img
						src={arrowAbout2}
						alt="flecha"
						className="flecha w-[30%] sm:w-[40%] absolute top-[90%] sm:top-[-10%] left-[65%] sm:left-[40%] sm:rotate-[0.82turn] "
					/>
					<img
						src={arrowAbout}
						alt="PLATO"
						className="flecha w-[25%] sm:w-[30%] left-0 sm:left-[25%] top-[90%] sm:top-[55%] absolute rotate-[0.4turn] sm:rotate-[0.1turn] "
					/>
				</div>
				<aside className="w-full sm:w-1/3 h-full">
					<content className="h-1/3 w-full flex flex-col items-center sm:items-left justify-center sm:justify-end ">
						<LargeText
							textBold={true}
							size="text-2xl hidden sm:flex"
							text="Síguenos en nuestras redes sociales"
						/>
						<div className="w-1/2 sm:w-full h-fit sm:h-32 flex flex-row items-center justify-around">
							<a
								href="https://www.instagram.com/chamonos_pue?igsh=ZGcxeDIwbno4dXo2"
								target="_blank"
								className="redes h-10 sm:h-20 w-12 sm:w-24 cursor-pointer flex items-center"
								rel="noopener noreferrer"
							>
								<img
									src={insta}
									alt="Instagram"
									className="h-[100%] w-[100%]"
								/>
							</a>
							<a
								href="https://www.facebook.com/profile.php?id=61560382410506&mibextid=LQQJ4d"
								target="_blank"
								className="redes cursor-pointer  z-20"
								rel="noopener noreferrer"
							>
								<img className="w-12 sm:w-24 h-10 sm:h-20" src={face} alt="redes" />
							</a>
							<a
								href="https://www.tiktok.com/@chamonos_pue?_t=8oIpzjMtURy&_r=1"
								target="_blank"
								className=" redes cursor-pointer  z-20"
								rel="noopener noreferrer"
							>
								<img className="w-12 sm:w-24 h-10 sm:h-20" src={tiktok} alt="redes" />
							</a>
						</div>
					</content>
					<div className="w-full h-2/3 flex items-center justify-center relative" >
						<img src={carro} className="w-[85%] sm:w-full relative sm:absolute sm:right-[33%] top-[23%] sm:scale-150 " alt="Chamonos carro" />
					</div>
				</aside>
			</section>
			<section className="h-fit sm:h-screen w-full my-16 sm:my-0 flex flex-col relative ">
				<div className="w-full h-2/3 flex">
					<div
						className="w-1/2 hidden sm:flex"
						style={{
							backgroundImage: `url(${chef})`,
							backgroundSize: "contain",
							backgroundRepeat: "no-repeat",
							backgroundPosition: "center",
						}}
					>
						<img
							src={plant}
							alt="PLANT"
							style={{
								position: "relative",
								height: "50%",
                                top:"10%",
								transform: "rotate(0.75turn)",
								left: "-15%",
							}}
						/>
						<div
							style={{ position: "absolute", left: "40%", top: "0%" }}
							className="w-[10%] h-[15%] border-yellow-500 border-r-4 border-b-4"
						/>
					</div>
					<div className="w-full sm:w-1/2 h-full flex flex-col justify-center text-center sm:text-right pr-0 sm:pr-12 space-y-8">
						<p className="text-[#FFE500] text-3xl sm:text-6xl font-extrabold font-titulo">
							Nuestras Cocinas
						</p>
						<div>
							<div className="flex sm:text-rigth px-4 sm:px-0 justify-center sm:justify-end w-full">
								<LargeText text="En&nbsp;" size="text-2xl" />
								<LargeText
									textBold={true}
									text="Contenedores,&nbsp;"
									colorText="text-[#FFE500]"
									size="text-2xl"
								/>
							</div>
							<LargeText
									text="cada cocina ofrece un menú único,"
									size="text-2xl"
							/>
							<LargeText
								text=" creado por chefs de renombre. "
								size="text-2xl"
							/>
						</div>
						<LargeText
							text="¡Descubre sabores excepcionales diseñados especialmente para usted!"
							textBold={true}
							size="text-2xl"
						/>
					</div>
				</div>
				<div className="w-full h-1/3  flex-row items-center justify-around">
					<CirclesAbout />
				</div>
			</section>
				<Footer />
		</div>
	);
};

export default AboutPage;
