export const Usuario = [
    {
        id: 1,
        nombre: "PRUEBA",
        apellidos: "APELLIDO1 APELLIDO2",
        fechaNacimiento: "17/06/2000",
        celular: 1234567890,
        email: "correo@correo.com",
        genero: "mujer",
        pagos:[
            {
                id:0,
                main:false,
                tipo:"efectivo",
                numero:null,
                nombre:null,
                apellidos:null,
                expiracion:null,
                cvv:null

            },
            {
                id:1,
                main:false,
                tipo:"tarjeta",
                numero: 1234123412341234,
                nombre:"nomTarjeta",
                apellidos:"Apellidos tarjeta",
                expiracion:"23/05/2028",
                cvv:"780"
            },
            {
                id:2,
                main:true,
                tipo:"tarjeta",
                numero: 1234123412349999,
                nombre:"nombre",
                apellidos:"Ap",
                expiracion:"24/05/2028",
                cvv:"089"
            },
            {
                id:3,
                main:false,
                tipo:"tarjeta",
                numero: 5700123412349999,
                nombre:"nombre2",
                apellidos:"Ap2",
                expiracion:"24/05/2028",
                cvv:"099"
            },
        ],
        direcciones: [
            {
                id:0,
                main:false,
                calle:"Calle prueba",
                colonia:"Colonia prueba",
                cp:"3232",
                municipio:"Municipio prueba",
                estado:"Estado prueba",
                tipo:"",
                etiqueta:"",
            },
            {
                id:1,
                main:true,
                calle:"Calle2 prueba",
                colonia:"Colonia2 prueba",
                cp:"0000",
                municipio:"Municipio2 prueba",
                estado:"Estado prueba2",
                tipo:"",
                etiqueta:"",
            }
        ]
    }
];