import React, { useState, useEffect } from "react";
import { Form, Formik } from "formik";
import InputFFAdmin from "../AdminAtoms/InputFFAdmin";
import { getKitchen } from "../../api";
import ButtonForm from "../atoms/ButtonForm";
import { UPDATE_PRODUCT } from "../../apiAdmin";
import { toast } from "react-toastify";

const EditProductPadel = ({ row ,onClose, refetch }) => {
    const [kitchens, setKitchens] = useState([]);
    const [loading, setLoading] = useState(false);

    const getKitchens = async () => {
        try {
            setLoading(true);
            const data = await getKitchen();
            const filteredKitchens = data.filter(kitchen => kitchen.tipo === "CocinaPadel");
            setKitchens(filteredKitchens);
        } catch (error) {
            console.error("Error fetching kitchen", error);
        } finally {
            setLoading(false);
        }
    };
    
    const options = kitchens.map(kitchen => ({
        value: kitchen.id,
        label: kitchen.nombre,
    }));

    useEffect(() => { getKitchens(); }, []);

    const initialValues = {
        nombre: "" || row.nombre,
        descripcion: "" || row.descripcion,
        imagen: "" || row.imagen,
        precio: "" || row.precio,
        status: "" || row.status,
        id_cocina: "" || row.id_cocina,
    }

    const validate = (values) => {
        const errors = {};
        if (!values.nombre) { errors.nombre = "Campo requerido"; }
        if (!values.status) { errors.status = "Debe tener un estatus"; }
        if (!values.id_cocina) { errors.id_cocina = "Debe tener una cocina"; }
		if (values.precio < 0) { errors.precio = "El precio no puede ser negativo"; }
        return errors;
    }

    const optionsStatus = [
		{ label: "Disponible", value: "Disponible" },
		{ label: "Minimo", value: "Minimo" },
		{ label: "Oculto", value: "Oculto" },
	];

    const handleSubmit = async(values)  => {
        try {
            await UPDATE_PRODUCT(row.id, values);
            onClose();
            refetch();
            toast.success("Editado exitosamente");
        } catch (error) {
            console.error(error);
            toast.error("Error al editado");
        }
    }

    return(
        <div>
            <Formik 
                onSubmit={handleSubmit}
                initialValues={initialValues}
                validate={validate}
            >
                {({ handleSubmit }) => (
                    <Form onSubmit={handleSubmit} >
                        <div className="w-full flex flex-col md:flex-row space-x-0 md:space-x-4">
                            <div className="w-full space-y-4">
                                <InputFFAdmin name="nombre" label="Nombre"/>
                                <InputFFAdmin name="imagen" label="Imagen"/>
                                <InputFFAdmin name="status" label="Estatus" type="select" options={optionsStatus} />
                            </div>
                            <div className="w-full space-y-4 mt-4 md:mt-0">
                                <InputFFAdmin name="descripcion" label="Descripcion"/>
                                <InputFFAdmin name="precio" label="Precio" type="number"/>
                                <InputFFAdmin name="id_cocina" label="Cocina" type="select" options={options} />
                            </div>
                        </div>
                        <ButtonForm bgColor={loading ? "bg-gray-300" : "bg-violet-300"} text="Editar" width="ml-auto w-full md:w-64 mt-6" type="submit"/>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default EditProductPadel; 