import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GET_PRODUCTS_BY_KITCHEN, getKitchenById } from "../../api";
import CardMenuPublic from "../organisms/CardMenuPublic";
import LargeText from "../atoms/LargeText";
import ButtonForm from "../atoms/ButtonForm";
import { useNavigate } from "react-router-dom";
import H2Custom from "../atoms/H2Custom";

const MenuProducts = () => {
    const { kitchenId } = useParams();
    const [loading, setLoading] = useState(false);
    const [menu, setMenu] = useState([]);
    const [kitchen, setKitchen] = useState(null);
    const navigate = useNavigate();

    const handleNavigate = () => {
        navigate("/menu");
    }

    const fetchData = useCallback(async () => {
        try {
            setLoading(true);
            const data = await GET_PRODUCTS_BY_KITCHEN(kitchenId);
            if (data === 404) {
                setMenu(null);
            } else {
                setMenu(data);
            }
            const dataKitchen = await getKitchenById(kitchenId);
            setKitchen(dataKitchen);
        } catch (error) {
            setMenu([]);
            console.error(error);
        } finally {
            setLoading(false);
        }
    }, [kitchenId]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    useEffect(() => {
        document.title = "Platillos - Contenedor";
    }, []); 

    const sortedMenu = menu !== null 
    ? [...menu].sort((a, b) => {
        if (a.categoria === null && b.categoria === null) return 0;
        if (a.categoria === null) return 1;
        if (b.categoria === null) return -1;
        if (a.categoria !== b.categoria) {
            return a.categoria - b.categoria;
        }
        return a.nombre.localeCompare(b.nombre);
    }) 
    : [];

    return (
        <div className="p-4 space-y-4">
            <div className="flex flex-row justify-between items-center px-4">
                <H2Custom 
                    size="text-3xl" colorText="text-yellow-300" textBold textAling="text-center" 
                    text={kitchen?.nombre !== undefined ? `Menú de ${kitchen?.nombre}` : "Cargando"} 
                />
                <ButtonForm 
                    text="Ver más cocinas" bgColor="bg-black" width="w-40" 
                    onClick={handleNavigate} textColor="text-yellow-200 border-2 border-yellow-200" 
                />
            </div>
            <div className="w-full flex flex-wrap justify-between">
                {loading === true && 
                    <LargeText 
                    text="Cargando..." 
                    size="text-5xl" 
                    colorText="text-yellow-300" 
                    textBold 
                    textAling="text-center" 
                    />
                }
                {sortedMenu.length > 0 && loading === false ? (
                    sortedMenu.map((product) => (
                        <CardMenuPublic key={product.id} product={product} />
                    ))
                ) : (
                    <LargeText 
                        text="No hay productos en la cocina" 
                        size="text-5xl" 
                        colorText="text-yellow-300" 
                        textBold 
                        textAling="text-center" 
                    />
                )}
            </div>
        </div>
    );
}

export default MenuProducts;