import React, { useState } from "react";
import CardLastMinute from "../../atoms/Guia/CardLastMinute";
import ButtonForm from "../../atoms/ButtonForm";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { LastMinuteProduct } from "../../data/DataMenu";
import { useProducts } from "../../../context/ProductContext";

const LastMinute = ({ onClose }) => {
    const { allProducts, setAllProducts, total, setTotal } = useProducts();
    const navigate = useNavigate();
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [isButtonDisabled2, setIsButtonDisabled2] = useState(false);

    const handleAdd = () => {
        navigate("/guia");
        toast.warning("Carrito actualizado")
        onClose();
    }

    const handleSkip = () => {
        navigate("/guia");
        onClose();
    }

    const handleAddProduct = product => {
        toast.success("Producto añadido " + product.platillo);
        if (allProducts.find(item => item.id === product.id)) {
            const products = allProducts.map(item => 
                item.id === product.id 
                ? {...item, count: item.count + 1} 
                : item 
            );
            setTotal(total + product.precio * product.count);
            setAllProducts([...products]);
        } else {
            setTotal(total + product.precio * product.count);
            setAllProducts([...allProducts, product]);
        }
        setIsButtonDisabled(false);
        setIsButtonDisabled2(true);
    }

    const botonClass = `${isButtonDisabled ? ("bg-transparent text-white") : ("bg-yellow-300") }`
    const botonClass2 = `${isButtonDisabled2 ? ("bg-transparent text-white") : ("bg-yellow-300") }`
    
    return (
        <div className="flex flex-col h-fit space-y-4">
            <div className="flex flex-col h-4/5 w-full space-y-4">
                <div className="flex flex-col sm:flex-row h-1/2 sm:space-x-4">
                    {LastMinuteProduct.slice(0, 2).map((item, index) => (
                        <CardLastMinute key={index} item={item} onClick={() => handleAddProduct(item)} />
                    ))}
                </div>
                <div className="flex flex-col sm:flex-row h-1/2 sm:space-x-4">
                    {LastMinuteProduct.slice(2, 4).map((item, index) => (
                        <CardLastMinute key={index} item={item} onClick={() => handleAddProduct(item)} />
                    ))}
                </div>
            </div>
            <div className="w-full bg-green-800 flex flex-col sm:flex-row h-fit px-6 sm:px-24 sm:space-x-24">
                <ButtonForm 
                    text="En otro momento" 
                    onClick={handleSkip} 
                    disabled={isButtonDisabled2} 
                    bgColor={botonClass2} 
                    textColor={botonClass2}  
                />
                <ButtonForm 
                    text="Agregar a mi pedido" 
                    bgColor={botonClass} 
                    textColor={botonClass}  
                    onClick={handleAdd} 
                    disabled={isButtonDisabled}
                />
            </div>
        </div>
    );
}

export default LastMinute;
