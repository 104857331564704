import { useNavigate } from "react-router-dom";

export const useNavigation = () => {
  const navigateToCurrentSection = (sectionId) => {
    navigate(`${window.location.pathname}#${sectionId}`);
  };

  const navigate = useNavigate();

  const navigateToLanding = () => navigate("/");
  const navigateToHome = () => navigate("/home");
  const navigateToGuia = () => navigate("/guia");
  const navigateToCaja = () => navigate("/caja");
  const navigateToAbout = () => navigate("/about");
  const navigateToLogin = () => navigate("/login");
  const navigateToCocina = () => navigate("/menuAdmin");
  const navigateToConfig = () => navigate("/config");
  const navigateToAdmin = () => navigate("/kitchens");
  const navigateToRegister = () => navigate("/register");
  const navigateToCajaPadel = () =>navigate("/cajaPadel");
  const navigateToCocinasSection = () => navigate("/home#section-cocinas");
  const navigateToServicesSection = () => navigate("/about#section-services");
  const navigateToDirectionsSection= () => navigate("/config?section=direcciones");

  return {
    navigateToHome,
    navigateToGuia,
    navigateToConfig,
    navigateToCocinasSection,
    navigateToDirectionsSection,
    navigateToRegister,
    navigateToAbout,
    navigateToLanding,
    navigateToCurrentSection,
    navigateToServicesSection,
    navigateToLogin,
    navigateToCocina,
    navigateToCaja,
    navigateToAdmin,
    navigateToCajaPadel,
  };
};
