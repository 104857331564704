import React, { useState } from "react";
import { FaPaypal } from "react-icons/fa";
import { CiCreditCard1 } from "react-icons/ci";
import { TbCreditCardPay } from "react-icons/tb";
import { MdOutlineArrowBackIos } from "react-icons/md";
import { LiaMoneyBillWaveAltSolid } from "react-icons/lia";
import useModal from "../../Hooks/useModal";
import LargeText from "../../atoms/LargeText";
import ButtonForm from "../../atoms/ButtonForm";
import RadioButtonsList from "../../atoms/RadioButtonsList";
import Modal from "../../molecules/Modal";
import AgregarTarjeta from "../../molecules/Guia/AgregarTarjeta";

const Pagos = ({ Usuario }) => {
    const { isOpen, openModal, closeModal } = useModal();
    const [addingCard, setAddingCard] = useState(false);
    const [selectedOption, setSelectedOption] = useState(
        Usuario.pagos.find(pago => pago.main).id
    );

    const handleOptionChange = (event) => {
        setSelectedOption(Number(event.target.value));
    };

    const options = Usuario.pagos.map(pago => ({
        value: pago.id,
        label: pago.tipo === "efectivo" ? "Efectivo" : `Tarjeta terminada en ${String(pago.numero).slice(-4)}`,
        icon: pago.tipo === "efectivo" ? <LiaMoneyBillWaveAltSolid className="w-6 h-6" /> : <CiCreditCard1 className="w-6 h-6" />
    }));

    return (
        <div className="w-full max-h-[85%] bg-gray-700 rounded-md p-2 sm:p-8">
            {addingCard ? (
                <ButtonForm icon={<MdOutlineArrowBackIos className="h-6 w-6" />} onClick={() => setAddingCard(false)} divStyles="pl-6" />
            ) : null}
            <LargeText text={addingCard === false ? "Métodos de pago" : "Agregar tarjeta"} textBold colorText="text-yellow-300" />
            {addingCard ? (
                <div>
                    <ButtonForm 
                        icon={<CiCreditCard1 className="w-8 h-8" />} 
                        text="Tarjeta de Crédito/Débito" divStyles="pl-6" 
                        bgColor="transparent border-2 border-white" 
                        onClick={openModal}
                    />
                    <ButtonForm icon={<FaPaypal className="h-16" />} text="Paypal" divStyles="pl-6" bgColor="transparent border-2 border-white"/>
                    {isOpen && 
                <Modal onClose={closeModal} title="Agregar tarjeta" width="w-3/5" height="h-auto" >
                    <AgregarTarjeta onClose={closeModal}/>
                </Modal>
            }
                </div>
            ) : (
                <div>
                    <RadioButtonsList
                        name="pagos"
                        options={options}
                        selectedOption={selectedOption}
                        onChange={handleOptionChange}
                    />
                    <ButtonForm icon={<TbCreditCardPay className="w-8 h-8"/>} text="Agregar tarjeta" divStyles="pl-6" bgColor="transparent border-2 border-white"  onClick={() => setAddingCard(true)}  />
                </div>
            )}
        </div>
    );
}

export default Pagos;
