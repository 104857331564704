import React from "react";
import contenedor from "../../assets/Imagenes/neon.png"
import LargeText from "../../atoms/LargeText";

const PreparandoPedido = ({onClose}) =>{
    return(
        <div onClick={onClose} className="fixed bottom-0 left-0 h-screen w-screen bg-black bg-opacity-70 flex flex-col items-center justify-center space-y-4 ">
            <img src={contenedor} alt="contenedor" className="w-[20%]" />
            <div className="h-4 w-1/3 bg-slate-300 rounded-full"  />
            <LargeText text="Creando tu pedido" size="text-2xl" textBold/>
        </div>
    )
}

export default PreparandoPedido;