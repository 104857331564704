import cardMenu from "../assets/Imagenes/cardMenu1.png"
import cardMenu2 from "../assets/Imagenes/cardMenu2.png"
import cardMenu3 from "../assets/Imagenes/cardMenu3.png"
import cardMenu4 from "../assets/Imagenes/cardMenu4.png"
import cardMenu5 from "../assets/Imagenes/cardMenu5.png"
import cardMenu6 from "../assets/Imagenes/cardMenu6.png"

export const Menu = [
    {
        id:1,
        count:0,
        cocina: 1,
        platillo: "Baguette al pastor",
        descripcion: "Pan de baguette artesanal con especias, con 150 grm de carne estilo pastor, cebolla caramelizada, jitomate, lechuga, aderezo de la casa",
        precio: 115,
        imagen: cardMenu,
        extras: {
        }
    },
    {
        id:2,
        count:1,
        cocina: 1,
        platillo: "New York Sándwich",
        descripcion: "ándwich con 2 huevos, manchego, tocino, champiñones, alioli, pan a elección.",
        precio: 115,
        imagen: cardMenu2,
        extras: {}
    },
    {
        id:3,
        count:1,
        cocina: 1,
        platillo: "Baguette Honey Mustard",
        descripcion: "Pechuga de pollo deshebrada, tocino, jamón, queso cheddar amarillo, aguacate, lechuga, jitomate, mayonesa, mostaza.",
        precio: 100,
        imagen: cardMenu3,
        extras: {
            extra1: {
                tituloExtra: "Aderezos",
                requied:false,
                multiple:true,
                1:{
                    subtituloExtra:"Salsa de frutos rojos",
                    precioExtra:10
                },
                2:{
                    subtituloExtra:"Ranch",
                    precioExtra:10
                },
                3:{
                    subtituloExtra:"Honey",
                    precioExtra:10
                },
            },
            extra2: {
                tituloExtra:"leche",
                requied:true,
                multiple:false,
                1:{
                    subtituloExtra:"entera",
                    precioExtra:10
                },
                2:{
                    subtituloExtra:"deslactosada",
                    precioExtra:10
                },
                3:{
                    subtituloExtra:"almendras",
                    precioExtra:10
                },
                4:{
                    subtituloExtra:"coco",
                    precioExtra:10
                },
            },
            extra3: {
                tituloExtra:"Topping",
                requied:false,
                multiple:true,
                1:{
                    subtituloExtra:"blueberry",
                    precioExtra:10
                },
                2:{
                    subtituloExtra:"fresa",
                    precioExtra:20
                },
                3:{
                    subtituloExtra:"platano",
                    precioExtra:10
                },
            },
            extra4:{
                tituloExtra:"Cubiertos",
                requied:true,
                multiple:false,
                1:{
                    subtituloExtra:"si",
                    precioExtra:10
                },
                2:{
                    subtituloExtra:"no",
                    precioExtra:0
                }
                }
        }
    },
    {
        id:4,
        count:1,
        cocina: 1,
        platillo: "Plato 4",
        descripcion: "Nose",
        precio: 20,
        imagen: cardMenu4,
        cubiertos: true,
        extras: {}
    },
    {
        id:5,
        count:1,
        cocina: 1,
        platillo: "Plato 5",
        descripcion: "5",
        precio: 5,
        imagen: cardMenu5,
        extras: {
        }
    },
    {
        id:6,
        count:0,
        cocina: 1,
        platillo: "plato 6",
        descripcion: "6",
        precio: 10,
        imagen: cardMenu6,
        extras: {}
    },
    {
        id:7,
        count:1,
        cocina: 1,
        platillo: "7",
        descripcion: "7",
        precio: 10,
        imagen: cardMenu,
        extras: {}
    },
    {
        id:8,
        count:1,
        cocina: 1,
        platillo: "8",
        descripcion: "8",
        precio: 100,
        imagen: cardMenu,
        extras: {}
    },
    {
        id:9,
        count:1,
        cocina: 3,
        platillo: "Ensalada con Salmon Asado",
        descripcion: "Pan de baguette artesanal con especias, con 150 grm de carne estilo pastor, cebolla caramelizada, jitomate, lechuga, aderezo de la casa",
        precio: 90,
        imagen: cardMenu,
        extras: {}
    },
    {
        id:10,
        count:1,
        cocina: 3,
        platillo: "wok",
        descripcion: "wok",
        precio: 190,
        imagen: cardMenu,
        extras: {}
    },
    {
        id:11,
        count:1,
        cocina: 3,
        platillo: "1",
        descripcion: "1",
        precio: 100,
        imagen: cardMenu,
        extras: {}
    },
    {
        id:12,
        count:1,
        cocina: 5,
        platillo: "Barbacoa",
        descripcion: "Barbacoa nomas",
        precio: 300,
        imagen: cardMenu,
        extras: {}
    },
    {
        id:13,
        count:1,
        cocina: 5,
        platillo: "Paquete 1",
        descripcion: "Paquete, tortillas y refresco ,1 kg de carne",
        precio: 200,
        imagen: cardMenu,
        extras: {}
    },
    {
        id:14,
        count:1,
        cocina: 2,
        platillo: "Baguette al pastor",
        descripcion: "Pan de baguette artesanal con especias, con 150 grm de carne estilo pastor, cebolla caramelizada, jitomate, lechuga, aderezo de la casa",
        precio: 115,
        imagen: cardMenu,
        extras: {
        }
    },
    {
        id:15,
        count:1,
        cocina: 4,
        platillo: "Baguette al pastor",
        descripcion: "Pan de baguette artesanal con especias, con 150 grm de carne estilo pastor, cebolla caramelizada, jitomate, lechuga, aderezo de la casa",
        precio: 115,
        imagen: cardMenu,
        extras: {
        }
    },
    {
        id:16,
        count:1,
        cocina: 6,
        platillo: "Baguette al pastor",
        descripcion: "Pan de baguette artesanal con especias, con 150 grm de carne estilo pastor, cebolla caramelizada, jitomate, lechuga, aderezo de la casa",
        precio: 115,
        imagen: cardMenu,
        extras: {
        }
    },
    {
        id:17,
        count:1,
        cocina: 6,
        platillo: "Baguette al pastor",
        descripcion: "Pan de baguette artesanal con especias, con 150 grm de carne estilo pastor, cebolla caramelizada, jitomate, lechuga, aderezo de la casa",
        precio: 115,
        imagen: cardMenu,
        extras: {
        }
    },
    {
        id:18,
        count:1,
        cocina: 8,
        platillo: "Baguette al pastor",
        descripcion: "Pan de baguette artesanal con especias, con 150 grm de carne estilo pastor, cebolla caramelizada, jitomate, lechuga, aderezo de la casa",
        precio: 115,
        imagen: cardMenu,
        extras: {
        }
    },
    {
        id:19,
        count:1,
        cocina: 9,
        platillo: "Baguette al pastor",
        descripcion: "Pan de baguette artesanal con especias, con 150 grm de carne estilo pastor, cebolla caramelizada, jitomate, lechuga, aderezo de la casa",
        precio: 115,
        imagen: cardMenu,
        extras: {
        }
    },
    {
        id:20,
        count:1,
        cocina:10,
        platillo: "Baguette al pastor",
        descripcion: "Pan de baguette artesanal con especias, con 150 grm de carne estilo pastor, cebolla caramelizada, jitomate, lechuga, aderezo de la casa",
        precio: 115,
        imagen: cardMenu,
        extras: {
        }
    },
    {
        id:21,
        count:1,
        cocina: 7,
        platillo: "Baguette al pastor",
        descripcion: "Pan de baguette artesanal con especias, con 150 grm de carne estilo pastor, cebolla caramelizada, jitomate, lechuga, aderezo de la casa",
        precio: 115,
        imagen: cardMenu,
        extras: {
        }
    },
]

export const LastMinuteProduct = [
        {
            id:99,
            count:1,
            cocina: 1,
            platillo: "Paquete 1",
            descripcion: "Paquete",
            precio: 200,
            imagen: cardMenu,
            extras: {}
        },
        {
            id:98,
            count:1,
            cocina: 2,
            platillo: "Cofe",
            descripcion: "carne",
            precio: 100,
            imagen: cardMenu,
            extras: {}
        },
        {
            id:97,
            count:1,
            cocina: 3,
            platillo: "Minute",
            descripcion: "tortillas",
            precio: 200,
            imagen: cardMenu,
            extras: {}
        },
        {
            id:96,
            count:1,
            cocina: 4,
            platillo: "last",
            descripcion: "1 ",
            precio: 200,
            imagen: cardMenu,
            extras: {}
        },
        {
            id:95,
            count:1,
            cocina: 5,
            platillo: "lol",
            descripcion: "Paquete, tortillas y refresco ,1 kg de carne",
            precio: 200,
            imagen: cardMenu,
            extras: {}
        },
];