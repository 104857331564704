import React, { useState } from "react";
import LargeText from "../../atoms/LargeText";
import { MdKeyboardArrowDown } from "react-icons/md";
import InputFieldFormik from "../../atoms/InputFieldFormik";
import { Field } from "formik";

const ExtraList = ({ item, cocina }) => {
  const [openExtraKey, setOpenExtraKey] = useState(Object.keys(item.extras)[0]);

  const handleExtraClick = (extraKey) => {
    setOpenExtraKey((prevKey) => (prevKey === extraKey ? null : extraKey));
  };

  const renderExtra = (extraKey, formik) => {
    const extra = item.extras[extraKey];
    const { tituloExtra, requied, ...options } = extra;
    const isOpen = openExtraKey === extraKey;

    return (
      <div key={extraKey} className="py-2">
        <div
          className="w-full flex flex-row justify-between items-center cursor-pointer"
          onClick={() => handleExtraClick(extraKey)}
        >
          <div className="flex flex-row space-x-2 justify-center items-center">
            <LargeText text={tituloExtra} textBold />
            <LargeText text={requied === true ? "Obligatorio" : null} size="text-sm" />
          </div>
          <MdKeyboardArrowDown size={30} color="white" />
        </div>
        {isOpen && (
          <ul>
            {Object.keys(options).map((optionKey) => {
              const option = options[optionKey];
              if (option && option.subtituloExtra && option.precioExtra !== undefined) {
                return (
                  <li key={optionKey}>
                    <InputFieldFormik
                      name={`extras.${extraKey}.${optionKey}`}
                      type="checkbox"
                      label={`${option.subtituloExtra} - Precio: $${option.precioExtra}`}
                      bgColor={cocina.props}
                    />
                  </li>
                );
              }
              return null;
            })}
          </ul>
        )}
      </div>
    );
  };

  if (!item.extras || Object.keys(item.extras).length === 0) {
    return (
      <div>
        <LargeText text="Comentarios" textBold />
        <textarea
          rows="2"
          maxLength="200"
          className={`w-full ${cocina.props_color} rounded-xl border-2 p-2 border-white text-white focus:border-yellow-300 focus:outline-none`}
        />
      </div>
    );
  }

  return (
    <div className="h-fit w-full p-4 overflow-y-scroll scroll-behavior-smooth">
      {Object.keys(item.extras).map((extraKey) => {
        return (
          <Field name={`extras.${extraKey}`}>
            {(props) => renderExtra(extraKey, props.form)}
          </Field>
        );
      })}
      <LargeText text="Comentarios" textBold />
      <textarea
        rows="2"
        maxLength="200"
        className={`w-full ${cocina.props} rounded-xl border-2 p-2 border-white text-white focus:outline-none focus:border-yellow-300`}
      />
    </div>
  );
};

export default ExtraList;
