import React from "react";
import { FaCcMastercard } from "react-icons/fa6";
import Link from "../../atoms/Link";
import LargeText from "../../atoms/LargeText";
import CardCocina from "../../molecules/Guia/CardCocina";
import { Cocina } from "../../data/DataCocinas";

const StatusPedido = ({allProducts, setAllProducts, total, setTotal, setPagar, totalPedido, propina, envio, Usuario}) =>{
    const cocinasConProductos = Cocina.filter(cocina =>
        allProducts.some(product => product.cocina === cocina.id)
    );
    const direccionPrincipal = Usuario.direcciones.find(direccion => direccion.main) || {};
    const pagoPrincipal = Usuario.pagos.find(pago => pago.main) || {};

    return (
        <main className="w-full p-4 flex flex-col space-y-4">
            <header className="flex flex-col rounded-md p-4 bg-gray-600 space-y-4">
                <LargeText text="Estatus de tu pedido" textBold size="text-4xl" />
                <div className="flex flex-row">
                    <LargeText text="La cocina&nbsp;" colorText="text-yellow-300" textBold />
                    <LargeText text="recibió tu pedido"  />
                </div>
            </header>
            <footer className="w-full flex flex-col  sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4 justify-between">
                <section className="w-full sm:w-2/3 rounded-md space-y-4 flex flex-col">
                    <div className="bg-gray-600 w-full rounded-md p-4">
                        <LargeText text="Cocinas" textBold />
                        <div className="h-[.5px] w-full border-[1px] border-white " />
                        {cocinasConProductos.map((cocina, index)=>{
                            const productosPorCocina = allProducts.filter(product => product.cocina === cocina.id);
                            return(
                            <CardCocina key={index} cocina={cocina} cantidad={productosPorCocina.length} allProducts={allProducts} />
                        );})}
                    </div>
                    <div className="bg-gray-600 w-full rounded-md p-4 space-y-4">
                        <div className="w-full space-x-4 items-center flex flex-row">
                            <LargeText text="Tu cobro:"  />  
                            <LargeText text={`$ ${totalPedido}`} textBold />
                        </div>
                        <div className="h-[.5px] w-full border-[1px] border-white " /> 
                        <LargeText text="Costo total:" textBold colorText="text-yellow-300" />
                        <div className="flex flex-row justify-between">
                        <LargeText text="Costo de los productos" /> 
                        <LargeText text={`$ ${total}`} />
                    </div>
                    <div className="flex flex-row justify-between">
                        <LargeText text="Propina" /> 
                        <LargeText text={`$ ${propina}`} />
                    </div>
                    <div className="flex flex-row justify-between">
                        <LargeText text="Costo de envio" /> 
                        <LargeText text={`$ ${envio}`} />
                    </div>
                    <div className="flex flex-row justify-between">
                        <LargeText text="Total pagado" textBold /> 
                        <LargeText text={`$ ${totalPedido}`} textBold />
                    </div>
                    </div>
                    <div  className="bg-gray-600 flex flex-col p-4 space-y-4 rounded-md" >
                        <LargeText text="Tu método de pago" textBold />
                        <div className="h-[.5px] w-full border-[1px] border-white " />
                        <div className="flex flex-row items-center ">
                            <FaCcMastercard className="text-white mr-4" size={60}/>
                            <div className="flex flex-col">
                                <LargeText text={`Tarjeta terminada en ${String(pagoPrincipal.numero).slice(-4)}`} textBold />
                                <LargeText text={`${pagoPrincipal.nombre} ${pagoPrincipal.apellidos}`}/>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="w-full sm:w-1/3 space-y-4">
                    <div className="w-full bg-gray-600 p-4 flex flex-col rounded-md space-y-4">
                        <LargeText text="Detalles del envío" textBold /> 
                        <div className="h-[.5px] w-full border-[1px] border-white " />
                        <LargeText text="Dirección de la entrega" colorText="text-yellow-300" textBold />
                        <LargeText 
                            text={
                                `${direccionPrincipal.calle}, 
                                ${direccionPrincipal.colonia}, 
                                ${direccionPrincipal.cp}, 
                                ${direccionPrincipal.municipio}, 
                                ${direccionPrincipal.estado}`
                            } 
                        />
                        <LargeText text="Entrega Estimada" />
                        <LargeText text="02:52 PM - 03:07 PM" textBold />
                    </div>
                    <div className="w-full bg-gray-600 p-4 flex flex-col rounded-md ">
                        <Link value="¿Tienes alguna duda?" text="text-green-600"/>
                    </div>
                </section>
            </footer>
        </main>
    );
}

export default StatusPedido;